import {
    call, put, takeLatest, all, takeEvery,
} from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { DEV_ACTIONS_ROOT } from '../../api/routes';

import {
    GET_ACTION_LIST, SET_ACTION_LIST, ERR_ACTION_LIST,
    GET_PARAMETER_LIST, SET_PARAMETER_LIST, ERR_PARAMETER_LIST,
    EXECUTE_ACTION, SUCCESS_EXECUTE_ACTION, ERR_EXECUTE_ACTION,
} from './constants';

function* getActionList(action) {
    const {
        monorailId, containerKey, actionConfig = {},
    } = action.data;
    const { payload = {}, CUSTOM_ACTION_ROOT } = actionConfig;

    const queryString = getQueryStringFromObject({
        monorailId,
        ...payload,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CUSTOM_ACTION_ROOT || DEV_ACTIONS_ROOT}?${queryString}`,
        );

        console.error('🚀 ~ function*getActionList ~ queryString:', response);

        yield put({ type: SET_ACTION_LIST, data: { ...response, containerKey } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACTION_LIST, data: { err: e.message, containerKey } });
    }
}

function* getParameterList(action) {
    const {
        action: actionStr, monorailId, preFilledValues, containerKey, actionConfig = {},
    } = action.data;
    const { payload = {}, CUSTOM_ACTION_ROOT } = actionConfig;

    const queryString = getQueryStringFromObject({
        monorailId,
        ...payload,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CUSTOM_ACTION_ROOT || DEV_ACTIONS_ROOT}/${actionStr}/parameters?${queryString}`,
        );

        let modifiedData;

        // if we have a fields inputted on UI, modify server param list with their values
        if (preFilledValues) {
            const { mergeKey } = preFilledValues;
            const parameterList = response.parameterList.map((serverItem) => ({
                ...serverItem,
                ...preFilledValues.paramList.find((clientItem) => clientItem[mergeKey] === serverItem[mergeKey]),
            }));
            modifiedData = { parameterList };
        }

        const parameterData = modifiedData || response;

        yield put({ type: SET_PARAMETER_LIST, data: { ...parameterData, containerKey } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_PARAMETER_LIST, data: { err: e.message, containerKey } });
    }
}

function* executeAction(action) {
    const {
        action: actionStr, parameters, monorailId, resolve, containerKey, actionConfig = {},
    } = action.data;
    const { payload = {}, CUSTOM_ACTION_ROOT } = actionConfig;

    const requestBody = {
        monorailId,
        parameters,
        action: actionStr,
        ...payload,
    };
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${CUSTOM_ACTION_ROOT || DEV_ACTIONS_ROOT}/execute`,
            requestBody,
        );

        yield call(resolve);
        yield put({ type: SUCCESS_EXECUTE_ACTION, data: { ...response, containerKey } });

        toastify('Action has been performed successfully', 'success');
    } catch (e) {
        // const err = getErrLabel(e);
        // show error message as toast
        toastify(e.message, 'error');
        // show error description on UI
        yield put({ type: ERR_EXECUTE_ACTION, data: { err: e.description, containerKey } });
    }
}

export default function* devActionsSaga() {
    yield all(
        [
            yield takeEvery(GET_ACTION_LIST, getActionList),
            yield takeEvery(GET_PARAMETER_LIST, getParameterList),
            yield takeLatest(EXECUTE_ACTION, executeAction),
        ],
    );
}
