/**
 * @file Debit vs Credit Bar chart
 */

import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    ExpandableCard,
} from '../../components';
import { MetaInfoContext } from '../../context';
import { getAggregateDataPointsTV2 } from './actions';
import { selectAggregatedValue } from './selectors';
import { BARCHART_DIMENSIONS, staticColorMapping } from './constants';
import './style.scss';
import BarChartWrapper from './BarChartWrapper';

const CustomBarChart = (props) => {
    const {
        yValue, aggregation, onboardingDate, caseId,
    } = props;
    const dataArray = useSelector(selectAggregatedValue, shallowEqual);

    const dispatch = useDispatch();
    const metaInfo = useContext(MetaInfoContext);

    const {
        riskOps: {
            transactionViewV2Params: {
                lifetimeCreditDebitTrend: {
                    isExpanded: lifetimeCreditDebitTrendExpanded,
                },
            },
        },
    } = metaInfo;

    const [isExpanded, setIsExpanded] = useState(lifetimeCreditDebitTrendExpanded);

    useEffect(() => {
        if (caseId) dispatch(getAggregateDataPointsTV2({ caseId }));
    }, [caseId, dispatch]);

    if (!dataArray || dataArray.length === 0) return null;

    return (
        <ExpandableCard
            extClasses='trv2-expand-card mb-30 pb-20'
            isExpanded={isExpanded}
            setIsExpanded={() => setIsExpanded((prev) => !prev)}
            heading='Trends'
        >
            <div className='trv2-dcc-c'>
                <div className='m-auto'>
                    {dataArray.map((data) => (
                        data.title && (
                            <div className='trv2-chart-container'>
                                <BarChartWrapper
                                    key={data.title}
                                    data={data}
                                    aggregation={aggregation}
                                    dimensions={BARCHART_DIMENSIONS}
                                    yValue={yValue}
                                    onboardingDate={onboardingDate}
                                    staticColorMapping={staticColorMapping}
                                />
                            </div>
                        )
                    ))}
                </div>
                <div>*Timeline for this graph is lifetime and will not vary based on time filters</div>
            </div>
        </ExpandableCard>
    );
};

export default React.memo(CustomBarChart);
