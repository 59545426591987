/**
 * @file DocRedaction Saga
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { REDACT_DOCUMENTS_ENDPOINT, RISK_OPS_ROOT, GET_PRESIGNED_DOCUMENT_URLS_ENDPOINT } from '../../api/routes';

import {
    GET_DOC_REDACTION_QUEUE, SET_DOC_REDACTION_QUEUE, ERR_DOC_REDACTION,
    VIEW_ALL_DOC_REDACTION_CASES, VIEW_SINGLE_DOC_REDACTION_CASE, DEQUEUE_DOC_REDACTION_CASE,
    EXECUTE_DOC_REDACTION, EXECUTED_DOC_REDACTION, GET_PRESIGNED_DOCUMENT_URLS,
} from './constants';
import {
    setDocRedactionCurCaseAction, setDocRedactionCurViewAction,
    setPresignedDocUrlAction,
} from './actions';

const getModifiedcase = (currentCase, redactedDocUrls) => (
    currentCase.stock_guardian_ckyc_documents.documents.map((doc) => {
        const redactedDocUrl = redactedDocUrls[doc.file_name];
        return {
            ...doc,
            url: redactedDocUrl,
        };
    })
);

function* getDocRedactionQueueSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        yield put({ type: SET_DOC_REDACTION_QUEUE, data: response.elements });

        return response.elements;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DOC_REDACTION, data: { err: e.message } });

        return [];
    }
}

function* dequeueDocRedactionCaseSaga(action) {
    let { actorId, recordId } = action.data;
    const { payloadType, index } = action.data;
    actorId = btoa(actorId);
    recordId = btoa(recordId);
    const queryString = getQueryStringFromObject({ actorId, recordId, payloadType });

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        const queueElements = yield call(getDocRedactionQueueSaga, { data: { payloadType } });

        let DocRedactionView;
        let curCase;
        const queueLen = queueElements.length;

        if (queueLen === 0) { // if queue is empty, show all wealth ops cases
            DocRedactionView = VIEW_ALL_DOC_REDACTION_CASES;
            curCase = {};
        } else { // else show the next case in the queue
            DocRedactionView = VIEW_SINGLE_DOC_REDACTION_CASE;
            /**
             * if index is less than queue length, set the current case at that index
             * else set the current case as the last case in the queue
             */
            curCase = index < queueLen ? queueElements[index] : queueElements[queueLen - 1];
            const documentsMap = {};
            /**
             * create a map of document file names and their urls
             * this is used to get the presigned urls for the documents
             */
            curCase.stock_guardian_ckyc_documents.documents.forEach((doc) => {
                documentsMap[doc?.file_name] = doc?.url;
            });
            /**
             * get the redacted document urls
             */
            const redactedDocUrls = yield call(getPresignedDocumentUrl, { data: { documentsMap } });
            /**
             * modify the current case to include the redacted document urls
             */
            curCase = {
                ...curCase,
                stock_guardian_ckyc_documents: {
                    ...curCase.stock_guardian_ckyc_documents,
                    documents: getModifiedcase(curCase, redactedDocUrls),
                },
            };
        }

        yield put(setDocRedactionCurViewAction(DocRedactionView));
        yield put(setDocRedactionCurCaseAction({ ...curCase, index }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DOC_REDACTION, data: { err: e.message } });
    }
}

function* redeactDocuments(action) {
    const { applicationId, referenceId, redactedDocuments } = action.data;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            REDACT_DOCUMENTS_ENDPOINT,
            {
                applicationId,
                referenceId,
                redactedDocuments,
            },
        );

        toastify('Document Redaction has been performed successfully', 'success');
        yield put({ type: EXECUTED_DOC_REDACTION });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DOC_REDACTION, data: { err: e.message } });
    }
}

function* getPresignedDocumentUrl(action) {
    const { documentsMap, resolve, reject } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${GET_PRESIGNED_DOCUMENT_URLS_ENDPOINT}`,
            {
                documentsMap,
            },
        );
        if (resolve) yield call(resolve, { redactedDocUrls: response.file_name_s3_pre_signed_u_r_l_map });
        yield put(setPresignedDocUrlAction());
        return response.file_name_s3_pre_signed_u_r_l_map;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DOC_REDACTION, data: { err: e.message } });
        if (reject) yield call(reject, err);
        return {};
    }
}

export default function* DocRedactionSaga() {
    yield all(
        [
            yield takeLatest(GET_DOC_REDACTION_QUEUE, getDocRedactionQueueSaga),
            yield takeLatest(DEQUEUE_DOC_REDACTION_CASE, dequeueDocRedactionCaseSaga),
            yield takeLatest(EXECUTE_DOC_REDACTION, redeactDocuments),
            yield takeLatest(GET_PRESIGNED_DOCUMENT_URLS, getPresignedDocumentUrl),
        ],
    );
}
