/**
 * PathsHelper.js
 * File to store all the helping constants and functions for defining Navbar paths and routes in Layout
 * Used Object.freeze because of https://dev.to/bytebodger/javascript-constants-with-objectfreeze-4beg
 */

import Search from '../../Search';
import Home from '../../Home';
import Tickets from '../../Tickets';
import Scripts from '../../Scripts';
import SOP from '../../SOP';
import VKYCFlow from '../../VKYCFlow';
import VkycAuditorFlow from '../../VkycAuditorFlow';
import DocRedaction from '../../DocRedaction';

import {
    HOME_PATH, SCRIPTS_PATH, SOP_PATH, TICKETS_PATH,
    VKYC_PATH, VKYC_AUDITOR_PATH, SEARCH_PATH,
    DOCUMENT_REDACTION_PATH,
} from '../routes';

// Define role constants
export const ROLES_FOR_NORMAL_SHERLOCK_V2 = Object.freeze({
    AGENT: 'AGENT',
    ADMIN: 'ADMIN',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN_RESTRICTED: 'ADMIN_RESTRICTED',
});

export const ROLES_FOR_VKYC_AGENT = Object.freeze({
    VKYC_CALL_AGENT: 'VKYC_CALL_AGENT',
    STOCK_GUARDIAN_VKYC_CALL_AGENT: 'STOCK_GUARDIAN_VKYC_CALL_AGENT',
});

export const ROLES_FOR_VKYC_AUDITOR = Object.freeze({
    VKYC_CALL_AUDITOR: 'VKYC_CALL_AUDITOR',
    STOCK_GUARDIAN_VKYC_CALL_AUDITOR: 'STOCK_GUARDIAN_VKYC_CALL_AUDITOR',
});

export const ROLES = Object.freeze({
    ...ROLES_FOR_NORMAL_SHERLOCK_V2,
    ...ROLES_FOR_VKYC_AGENT,
    ...ROLES_FOR_VKYC_AUDITOR,
});

// Define screen constants
export const SCREENS = Object.freeze({
    SHERLOCK_V2_GENERIC: 'SHERLOCK_V2_GENERIC',
});

// send back a Map roles to screens
export const ROLE_TO_SCREEN_MAPPING = {};

const getRoleToScreenMapping = () => {
    Object.keys(ROLES_FOR_NORMAL_SHERLOCK_V2).forEach((role) => {
        ROLE_TO_SCREEN_MAPPING[role] = SCREENS.SHERLOCK_V2_GENERIC;
    });
    Object.keys(ROLES_FOR_VKYC_AGENT).forEach((role) => {
        ROLE_TO_SCREEN_MAPPING[role] = SCREENS.SHERLOCK_V2_GENERIC;
    });
    Object.keys(ROLES_FOR_VKYC_AUDITOR).forEach((role) => {
        ROLE_TO_SCREEN_MAPPING[role] = SCREENS.SHERLOCK_V2_GENERIC;
    });
};
getRoleToScreenMapping();

export const ROLE_OPTIONS = Object.freeze({
    [ROLES.VKYC_CALL_AUDITOR]: {
        isSideBarExpanded: false,
        isSearchBarVisible: false,
        redirectionToV1AccessLevelConfig: false,
    },
    [ROLES.VKYC_CALL_AGENT]: {
        isSideBarExpanded: false,
        isSearchBarVisible: false,
        redirectionToV1AccessLevelConfig: false,
    },
    [ROLES.STOCK_GUARDIAN_VKYC_CALL_AGENT]: {
        isSideBarExpanded: false,
        isSearchBarVisible: false,
        redirectionToV1AccessLevelConfig: false,
    },
    [ROLES.STOCK_GUARDIAN_VKYC_CALL_AUDITOR]: {
        isSideBarExpanded: false,
        isSearchBarVisible: false,
        redirectionToV1AccessLevelConfig: false,
    },
});

const ADMIN_NAVBAR_OPTIONS = {
    HOME: {
        label: 'Home',
        path: HOME_PATH,
        component: Home,
        img: './svgs/home.svg',
        id: 'home',
        subLinks: [],
    },
    SEARCH: {
        label: 'Search',
        path: SEARCH_PATH,
        component: Search,
        img: './svgs/search.svg',
        id: 'search',
        subLinks: [],
    },
    MY_TICKETS: {
        label: 'My Tickets',
        path: TICKETS_PATH,
        component: Tickets,
        img: './svgs/tickets.svg',
        id: 'tickets',
        subLinks: [],
    },
    SCRIPTS: {
        label: 'Scripts',
        path: SCRIPTS_PATH,
        component: Scripts,
        img: './svgs/scripts.svg',
        id: 'scripts',
        subLinks: [],
    },
    SOP: {
        label: 'SOP',
        path: SOP_PATH,
        component: SOP,
        img: './svgs/sop.svg',
        id: 'sop',
        subLinks: [],
    },
    DEFAULT: {
        label: 'Home',
        path: HOME_PATH,
        component: Home,
        img: './svgs/home.svg',
        id: 'home',
        subLinks: [],
    },
};

const VKYC_AGENT_NAVBAR_OPTIONS = {
    VKYC: {
        label: 'VKYC',
        path: VKYC_PATH,
        component: VKYCFlow,
        img: './svgs/home.svg',
        id: 'vkyc',
        subLinks: [],
    },
    DEFAULT: {
        label: 'VKYC',
        path: VKYC_PATH,
        component: VKYCFlow,
        img: './svgs/home.svg',
        id: 'vkyc',
        subLinks: [],
    },
};

const VKYC_AUDITOR_NAVBAR_OPTIONS = {
    VKYC: {
        label: 'VKYC AUDITOR',
        path: VKYC_AUDITOR_PATH,
        component: VkycAuditorFlow,
        img: './svgs/home.svg',
        id: 'vkyc-auditor',
        subLinks: [],
    },
    DEFAULT: {
        label: 'VKYC AUDITOR',
        path: VKYC_AUDITOR_PATH,
        component: VkycAuditorFlow,
        img: './svgs/home.svg',
        id: 'vkyc-auditor',
        subLinks: [],
    },
};

const ADDITIONAL_STOCK_GUARDIAN_AGENT_NAVBAR_OPTIONS = {
    DOCUMENT_REDACTION: {
        label: 'Document Redaction',
        path: DOCUMENT_REDACTION_PATH,
        component: DocRedaction,
        img: './svgs/document.svg',
        id: 'document-redaction',
        subLinks: [],
    },
};
// Define navbar mappings for roles
export const ROLE_TO_NAVBAR_MAPPING = {};

const getRoleToNavbarMapping = () => {
    Object.keys(ROLES_FOR_NORMAL_SHERLOCK_V2).forEach((role) => {
        ROLE_TO_NAVBAR_MAPPING[role] = Object.freeze(ADMIN_NAVBAR_OPTIONS);
    });
    Object.keys(ROLES_FOR_VKYC_AGENT).forEach((role) => {
        ROLE_TO_NAVBAR_MAPPING[role] = Object.freeze(VKYC_AGENT_NAVBAR_OPTIONS);
    });
    Object.keys(ROLES_FOR_VKYC_AUDITOR).forEach((role) => {
        ROLE_TO_NAVBAR_MAPPING[role] = Object.freeze(VKYC_AUDITOR_NAVBAR_OPTIONS);
    });

    ROLE_TO_NAVBAR_MAPPING[ROLES.STOCK_GUARDIAN_VKYC_CALL_AGENT] = {
        ...ROLE_TO_NAVBAR_MAPPING[ROLES.STOCK_GUARDIAN_VKYC_CALL_AGENT],
        ...ADDITIONAL_STOCK_GUARDIAN_AGENT_NAVBAR_OPTIONS,
    };
};

getRoleToNavbarMapping();

export const ACCESS_LEVEL_LIST = Object.entries(ROLES).map(([key, value]) => ({ label: key, value }));
