import {
    ERR_AVAILABLE_CALLS, ERR_CAPTURE_SCREENSHOT, ERR_CONCLUDE_VKYC_CALL, ERR_END_VKYC_CALL,
    ERR_EXTRACT_DOCUMENT_DETAILS, ERR_GENERATE_VKYC_REPORT, ERR_INITIATE_VKYC_CALL,
    ERR_MATCH_SCORE,
    ERR_ONBOARDING_STAGES,
    ERR_PERFORM_CLIENT_ACTION, ERR_UPLOAD_SCREEN_RECORDING, GET_AVAILABLE_CALLS,
    GET_CAPTURE_SCREENSHOT,
    GET_CONCLUDE_VKYC_CALL,
    GET_END_VKYC_CALL,
    GET_EXTRACT_DOCUMENT_DETAILS,
    GET_GENERATE_VKYC_REPORT,
    GET_HEALTH_CHECK,
    ERR_HEALTH_CHECK,
    GET_INITIATE_VKYC_CALL, GET_MATCH_SCORE, GET_ONBOARDING_STAGES, GET_PERFORM_CLIENT_ACTION, GET_VKYC_FLOW_STEP, GET_VKYC_STEP, SET_AVAILABLE_CALLS,
    SET_CAPTURE_SCREENSHOT, SET_CONCLUDE_VKYC_CALL, SET_END_VKYC_CALL, SET_EXTRACT_DOCUMENT_DETAILS, SET_GENERATE_VKYC_REPORT, SET_INITIATE_VKYC_CALL,
    SET_MATCH_SCORE,
    SET_ONBOARDING_STAGES,
    SET_PERFORM_CLIENT_ACTION,
    SET_UPLOAD_SCREEN_RECORDING,
    SET_VKYC_CAMERA_ORIENTATION,
    SET_VKYC_FLOW_STEP,
    SET_VKYC_IMAGE_URL,
    SET_HEALTH_CHECK,
    SET_VKYC_QUESTION_ANSWER,
    SET_VKYC_STEP,
    SET_VKYC_TITLE,
    UPLOAD_SCREEN_RECORDING,
    SET_LOADER_STATE,
    SET_PARTICIPANT_AUDIO_STREAM,
    CLEAR_PARTICIPANT_AUDIO_STREAM,
    SET_AUDIO_MUTE_FLAG,
    SET_REJECTION_REASONS,
    GET_VKYC_AVAILABLE_CALLS_COUNT,
    SET_DEVICE_PERMISSIONS,
    SET_CURRENT_PAGE,
    SET_CURRENT_STAGE_POINTER,
    SET_LOCAL_FILE_STORED_STATUS,
    SET_PARTICIPANT_JOINING_STATUS,
    SET_FACEMATCH_IMAGE_STATUS,
    SET_MEETING_LOADER_STATE,
    LOG_VKYC_STAGE_PROGRESS,
    SET_ERROR_VIEW,
} from './constants';

export const getAvailableCalls = (data) => ({
    type: GET_AVAILABLE_CALLS,
    data,
});

export const setAvailableCalls = (data) => ({
    type: SET_AVAILABLE_CALLS,
    data: {
        key: 'availableCallCount',
        ...data,
    },
});

export const errAvailableCalls = (data) => ({
    type: ERR_AVAILABLE_CALLS,
    data,
});

export const getInitiateCall = (data) => ({
    type: GET_INITIATE_VKYC_CALL,
    data,
});

export const setInitiateCall = (data) => ({
    type: SET_INITIATE_VKYC_CALL,
    data,
});

export const getHealthCheck = (data) => ({
    type: GET_HEALTH_CHECK,
    data,
});

export const setHealthCheck = (data) => ({
    type: SET_HEALTH_CHECK,
    data: {
        ...data,
        key: ['healthCheckStatus'],
    },
});

export const errInitiateCall = (data) => ({
    type: ERR_INITIATE_VKYC_CALL,
    data,
});

export const errtHealthCheck = (data) => ({
    type: ERR_HEALTH_CHECK,
    data,
});

export const getConcludeCall = (data) => ({
    type: GET_CONCLUDE_VKYC_CALL,
    data,
});

export const setConcludeCall = (data) => ({
    type: SET_CONCLUDE_VKYC_CALL,
    data,
});

export const errConcludeCall = (data) => ({
    type: ERR_CONCLUDE_VKYC_CALL,
    data,
});

export const getCaptureScreenshot = (data) => ({
    type: GET_CAPTURE_SCREENSHOT,
    data,
});

export const setCaptureScreenshot = (data) => ({
    type: SET_CAPTURE_SCREENSHOT,
    data,
});

export const errCaptureScreenshot = (data) => ({
    type: ERR_CAPTURE_SCREENSHOT,
    data,
});

export const getPerformClientAction = (data) => ({
    type: GET_PERFORM_CLIENT_ACTION,
    data,
});

export const setPerformClientAction = (data) => ({
    type: SET_PERFORM_CLIENT_ACTION,
    data,
});

export const errPerformClientAction = (data) => ({
    type: ERR_PERFORM_CLIENT_ACTION,
    data,
});

export const getOnboardingStages = (data) => ({
    type: GET_ONBOARDING_STAGES,
    data,
});

export const setOnboardingStages = (data) => ({
    type: SET_ONBOARDING_STAGES,
    data,
});

export const errOnboardingStages = (data) => ({
    type: ERR_ONBOARDING_STAGES,
    data,
});

export const getVKYCStep = (data) => ({
    type: GET_VKYC_STEP,
    data,
});

export const setVKYCStep = (data) => ({
    type: SET_VKYC_STEP,
    data,
});

export const getVKYCFlowStep = (data) => ({
    type: GET_VKYC_FLOW_STEP,
    data,
});

export const setVKYCFlowStep = (data) => ({
    type: SET_VKYC_FLOW_STEP,
    data,
});

export const setVKYCQuestionAnswers = (data) => ({
    type: SET_VKYC_QUESTION_ANSWER,
    data,
});

export const setTitle = (data) => ({
    type: SET_VKYC_TITLE,
    data,
});

export const setVKYCImageUrl = (data) => ({
    type: SET_VKYC_IMAGE_URL,
    data,
});

export const setVKYCCameraOrientation = (data) => ({
    type: SET_VKYC_CAMERA_ORIENTATION,
    data,
});

export const getExtractDocumentDetails = (data) => ({
    type: GET_EXTRACT_DOCUMENT_DETAILS,
    data,
});

export const setExtractDocumentDetails = (data) => ({
    type: SET_EXTRACT_DOCUMENT_DETAILS,
    data,
});

export const errExtractDocumentDetails = (data) => ({
    type: ERR_EXTRACT_DOCUMENT_DETAILS,
    data,
});

export const getMatchScore = (data) => ({
    type: GET_MATCH_SCORE,
    data,
});

export const setMatchScore = (data) => ({
    type: SET_MATCH_SCORE,
    data,
});

export const errMatchScore = (data) => ({
    type: ERR_MATCH_SCORE,
    data,
});

export const getEndVKYCCall = (data) => ({
    type: GET_END_VKYC_CALL,
    data,
});

export const setEndVKYCCall = (data) => ({
    type: SET_END_VKYC_CALL,
    data,
});

export const errEndVKYCCall = (data) => ({
    type: ERR_END_VKYC_CALL,
    data,
});

export const getGenerateVKYCReport = (data) => ({
    type: GET_GENERATE_VKYC_REPORT,
    data,
});

export const setGenerateVKYCReport = (data) => ({
    type: SET_GENERATE_VKYC_REPORT,
    data,
});

export const errGenerateVKYCReport = (data) => ({
    type: ERR_GENERATE_VKYC_REPORT,
    data,
});

export const uploadScreenRecord = (data) => ({
    type: UPLOAD_SCREEN_RECORDING,
    data,
});

export const setUploadScreenRecording = (data) => ({
    type: SET_UPLOAD_SCREEN_RECORDING,
    data,
});

export const errUploadScreenRecording = (data) => ({
    type: ERR_UPLOAD_SCREEN_RECORDING,
    data,
});

/**
 * This action is used for initiating the vkyc meeting , when meetingId is not available
 */

export const setLoaderState = (data) => ({
    type: SET_LOADER_STATE,
    data,
});

/**
 *  This action is used when meeting is in progress and we need to switch the loader state
 */
export const setMeetingLoaderState = (data) => ({
    type: SET_MEETING_LOADER_STATE,
    data,
});

export const setParticipantAudioStream = (stream) => ({
    type: SET_PARTICIPANT_AUDIO_STREAM,
    data: stream,
});

export const clearParticipantAudioStream = () => ({
    type: CLEAR_PARTICIPANT_AUDIO_STREAM,
});

export const muteAudioStatus = (data) => ({
    type: SET_AUDIO_MUTE_FLAG,
    data,
});

export const setRejectionReasons = (data) => ({
    type: SET_REJECTION_REASONS,
    data,
});

export const getVkycAvailableCallsCount = () => ({
    type: GET_VKYC_AVAILABLE_CALLS_COUNT,
});

export const setRequiredPermissionStatus = (data) => ({
    type: SET_DEVICE_PERMISSIONS,
    data,
});

export const setCurrentStep = (data) => ({
    type: SET_CURRENT_PAGE,
    data,
});

export const setCurrentStagePointers = (data) => ({
    type: SET_CURRENT_STAGE_POINTER,
    data,
});

export const setLocalFileStoredStatus = (data) => ({
    type: SET_LOCAL_FILE_STORED_STATUS,
    data,
});

export const setParticipantJoiningStatus = (data) => ({ type: SET_PARTICIPANT_JOINING_STATUS, data });

export const setFacematchImageStatus = (data) => ({
    type: SET_FACEMATCH_IMAGE_STATUS,
    data: {
        ...data,
        key: ['meetings', data.meetingId, 'stages', data.stepNumber, 'faceMatchImage'],
    },
});

export const logStageProgress = (data) => ({
    type: LOG_VKYC_STAGE_PROGRESS,
    data,
});

export const setErrorViewAction = (data) => ({
    type: SET_ERROR_VIEW,
    data: {
        ...data,
        key: ['errorView'],
    },
});
