/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { getProxiedUrl } from '../../../utils/downloadFile';
import { debounce } from '../../../utils/helpers';
import { ICONS_URL } from '../../../constants/assets';
import { caseConstants } from '../constant';

const PDFViewer = (props) => {
    const { url } = props;
    const canvasContainerRef = useRef(null);
    const sliderRef = useRef(null);
    const [scale, setScale] = useState(1.5); // Default scale
    const debouncedScaleRef = useRef(scale); // Keep track of debounced scale

    // Debounce the scale update to reduce render frequency
    const debouncedSetScale = useRef(
        debounce((value) => {
            debouncedScaleRef.current = value;
            setScale(value);
        }, 200), // Adjust delay as needed
    ).current;

    useEffect(() => {
        canvasContainerRef.current.innerHTML = '';
        const loadPDF = async () => {
            try {
                const loadingTask = window.pdfjsLib.getDocument(url);
                const pdf = await loadingTask.promise;

                const container = canvasContainerRef.current;

                // Clear the container before rendering
                container.innerHTML = '';

                // Load all the pages of the PDF
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: debouncedScaleRef.current });

                    // Create canvas element
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render the PDF page onto the canvas
                    const renderContext = {
                        canvasContext: context,
                        viewport,
                    };

                    await page.render(renderContext).promise;

                    // Append the canvas to the container
                    container.appendChild(canvas);
                }
            } catch (error) {
                const errorElement = document.createElement('div');
                errorElement.innerHTML = 'Unable to load PDF';
                canvasContainerRef.current.appendChild(errorElement);
            }
        };

        loadPDF();
    }, [url, scale]); // Only run on debounced scale updates

    // Handle drag for zoom functionality
    const handleDrag = (() => {
        let rafId = null;

        return (event) => {
            if (rafId) return;

            rafId = requestAnimationFrame(() => {
                const slider = sliderRef.current;
                const rect = slider.getBoundingClientRect();
                const offsetX = event.clientX - rect.left;

                // Normalize drag position to scale between 0.5 and 2
                const normalizedScale = Math.max(0.5, Math.min(2, (offsetX / rect.width) * 1.5 + 0.5));
                debouncedSetScale(normalizedScale); // Use debounced setter

                rafId = null; // Reset after rendering
            });
        };
    })();

    return (
        <div className='user-case-review-outer-container'>
            {/* PDF Viewer Container */}
            <div
                className='user-case-review-pdf-container'
                ref={canvasContainerRef}
            />

            {/* Drag-Based Zoom Slider */}
            <div
                ref={sliderRef}
                className='user-case-review-zoom-slider'
                onMouseDown={() => {
                    const onMouseMove = (e) => handleDrag(e);
                    const onMouseUp = () => {
                        document.removeEventListener('mousemove', onMouseMove);
                        document.removeEventListener('mouseup', onMouseUp);
                    };
                    document.addEventListener('mousemove', onMouseMove);
                    document.addEventListener('mouseup', onMouseUp);
                }}
                role='presentation'
            >
                <div
                    className='user-case-review-zoom-handle'
                    style={{
                        left: `${((debouncedScaleRef.current - 0.5) / 1.5) * 100}%`,
                    }}
                />
            </div>
        </div>
    );
};

const ImageViewer = (props) => {
    const { url } = props;
    return (
        <div className='user-case-review-image-viewer'>
            <img src={url} alt='doc' />
        </div>
    );
};

const DocViewer = (props) => {
    const { btnClassname, url, type } = props;
    const [isVisible, setVisibility] = useState(false);

    const customStylesForModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1,
        },
        content: {
            top: '5%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '32px 20px',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            fontSize: 16,
            width: '80%',
            border: 0,
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
            position: 'relative',
        },
    };

    const close = () => setVisibility((prev) => !prev);

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const getDoc = () => {
        switch (type) {
            case caseConstants.IMAGE:
                return (
                    <ImageViewer
                        url={getProxiedUrl(url)}
                    />
                );
            case caseConstants.PDF:
                return (
                    <PDFViewer url={getProxiedUrl(url)} />
                );
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {
                isVisible && (
                    <Modal
                        isOpen={isVisible}
                        style={customStylesForModal}
                        contentLabel='Uploaded Data Modal'
                    >
                        <div onContextMenu={handleContextMenu}>
                            <button
                                className='user-case-review-modal-close-btn'
                                type='button'
                                onClick={close}
                            >
                                <img src={ICONS_URL.CLOSE} alt='close' />
                            </button>
                            {
                                getDoc()
                            }

                        </div>
                    </Modal>
                )
            }
            <div className={btnClassname}>
                <button
                    onClick={close}
                    type='button'
                >View
                </button>
            </div>
        </React.Fragment>
    );
};

export default DocViewer;
