/**
 * @file DocRedaction constants
 */

import { APP_V2_KEY } from '../AppV2/constants';

// key to be used for reducers & selectors for wealth ops
export const DOC_REDACTION_KEY = 'DocRedaction';

// action constants
export const GET_DOC_REDACTION_QUEUE = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/GET_DOC_REDACTION_QUEUE`;
export const SET_DOC_REDACTION_QUEUE = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/SET_DOC_REDACTION_QUEUE`;
export const ERR_DOC_REDACTION = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/ERR_DOC_REDACTION`;
export const EXECUTE_DOC_REDACTION = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/EXECUTE_DOC_REDACTION`;
export const EXECUTED_DOC_REDACTION = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/EXECUTED_DOC_REDACTION`;
export const DEQUEUE_DOC_REDACTION_CASE = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/DEQUEUE_DOC_REDACTION_CASE`;
export const SET_DOC_REDACTION_CUR_VIEW = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/SET_DOC_REDACTION_CUR_VIEW`;
export const SET_DOC_REDACTION_CUR_CASE = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/SET_DOC_REDACTION_CUR_CASE`;
export const SET_DOC_REDACTION_PAYLOAD_TYPE = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/SET_DOC_REDACTION_PAYLOAD_TYPE`;
export const SET_DOC_REDACTION_QUEUE_FILTERS = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/SET_DOC_REDACTION_QUEUE_FILTERS`;
export const GET_PRESIGNED_DOCUMENT_URLS = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/GET_PRESIGNED_DOCUMENT_URLS`;
export const SET_PRESIGNED_DOCUMENT_URLS = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/SET_PRESIGNED_DOCUMENT_URLS`;

// view constants
export const VIEW_ALL_DOC_REDACTION_CASES = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/View/ALL_CASES`;
export const VIEW_SINGLE_DOC_REDACTION_CASE = `${APP_V2_KEY}/${DOC_REDACTION_KEY}/View/SINGLE_CASE`;

// value constants
export const PAYLOAD_TYPE_STOCKGUARDIAN_CKYC_DOCUMENTS = 'PAYLOAD_TYPE_STOCKGUARDIAN_CKYC_DOCUMENTS';
export const LIMIT_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 50, 70, 100];

export const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_STOCKGUARDIAN_CKYC_DOCUMENTS,
    isVisible: false,
};

export const LIMIT_DD_OG_STATE = {
    value: 2,
    isVisible: false,
};

export const DOCUMENT_REDACTION_DEV_ACTION_TYPES = ['STOCK_GUARDIAN_REDACT_CKYC_DOCUMENT'];

// All cases table labels
export const DOC_REDACTION_ALL_CASE_TABLE_LABEL_DATA = [
    {
        label: 'Sr. No',
        key: 'sr_no',
    },
    {
        label: 'Actor ID',
        key: 'actor_id',
    },
];

export const CUSTOM_FILE_TYPES_MAPPING = {
    FILE_TYPE_PDF: 'PDF',
    FILE_TYPE_JPEG: 'JPEG',
    FILE_TYPE_JPG: 'JPG',
    FILE_TYPE_TIFF: 'TIFF',
};

export const SUPPORTED_FILE_TYPES = '.jpg,.jpeg,.pdf,.tiff';

export const DOCUMENT_REDACTION_STATUS_OPTIONS = [
    {
        value: 'NOT_REDACTED',
        label: 'Not Redacted',
    },
    {
        value: 'REDACTED',
        label: 'Redacted',
    },
];
