/**
 *
 * Review a single Document Redaction case & check the following:
 * 1. Check if images are masked correctly
 *
 */

import React, { useState, memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button } from '../../../components';

import { selectDocRedactionCurCase, selectDocRedactionPayloadType } from '../selectors';
import { dequeueDocRedactionCaseAction, setDocRedactionCurViewAction } from '../actions';
import { VIEW_ALL_DOC_REDACTION_CASES } from '../constants';
import DocumentReview from '../DocumentReview';

const removeQueueModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        backgroundColor: '#F5F5F5',
    },
};

const SingleCase = () => {
    const dispatch = useDispatch();

    // Global state
    const payloadType = useSelector(selectDocRedactionPayloadType(), shallowEqual);
    const curCase = useSelector(selectDocRedactionCurCase(), shallowEqual);

    // Local state
    const [showExecuteInfoModal, setExecuteInfoModal] = useState(false);

    // Extract information from the case
    const { index, id: recordId, stock_guardian_ckyc_documents: { application_id: actorId } = {} } = curCase;

    const handleDequeueCase = () => {
        dispatch(dequeueDocRedactionCaseAction({
            actorId, recordId, index, payloadType: payloadType.value,
        }));
        setExecuteInfoModal(false);
    };

    return (
        <React.Fragment>
            <div className='doc-redac-hl__cr'>
                <button
                    className='doc-redac-backarrow__cr'
                    type='button'
                    onClick={() => dispatch(setDocRedactionCurViewAction(VIEW_ALL_DOC_REDACTION_CASES))}
                >
                    <img
                        className='doc-redac-backarrow'
                        alt='back-arrow'
                        src={backArrowIcon}
                    />
                </button>
                <div className='doc-redac-hl1'>Review Wealth Ops Case</div>
            </div>
            <DocumentReview />
            <div className='fccWrapper'>
                <Button
                    v2
                    primary
                    label='Close Case'
                    onClick={() => setExecuteInfoModal(true)}
                />

                <div className='doc-redac-hl2 ml-30'>Use this to load the next case once you finish reviewing the current case</div>
            </div>

            {/* Confirmation modal */}
            {showExecuteInfoModal && (
                <Modal
                    isOpen
                    style={removeQueueModalStyles}
                    contentLabel='Action Execution Modal'
                >
                    <div className='doc-redac-modal__hl'>
                        Are you sure you want to dequeue this case?
                    </div>
                    <div className='doc-redac-modal__sbhl'>
                        This change is irreversible and affects production data
                    </div>
                    <div className='frcsbWrapper doc-redac-modal__ctas'>
                        <Button
                            v2
                            secondary
                            label='Submit'
                            onClick={handleDequeueCase}
                        />
                        <Button
                            v2
                            primary
                            label='Cancel'
                            onClick={() => setExecuteInfoModal(false)}
                        />
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default memo(SingleCase);
