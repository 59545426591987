import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, LoaderOrError, Modal } from '../../../components';

import {
    getInitiateCall, muteAudioStatus, setCurrentStep,
    setAvailableCalls,
} from '../actions';
import { selectVkycData, selectVkycAvailableCallsCount } from '../selectors';
import { FAVICON_URL, FLOW_PROGRESS } from '../constants';
import { useWebSocket } from '../../../hooks';

const CallQueueScreen = (props) => {
    const { initiateRecordingSetup, stopRecording } = props;
    const dispatch = useDispatch();
    const [connectionOpen, response, send, socketError] = useWebSocket();

    const vkycData = useSelector(selectVkycData, shallowEqual);
    const availableCallCount = useSelector(selectVkycAvailableCallsCount);

    const [hasNotificationPermission, setHasNotificationPermission] = useState(false);
    const [notificationFavicon, setNotificationFavicon] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    const setAudioMuteFlag = (flag) => {
        dispatch(muteAudioStatus(flag));
    };

    useEffect(() => {
        if (vkycData?.data?.meetingId) {
            if (vkycData?.muteAudioFlag) setAudioMuteFlag(false);
            dispatch(setCurrentStep({ progress: FLOW_PROGRESS.IN_PROGRESS }));
        } else if (!vkycData?.devicePermissionStatus?.status) {
            stopRecording();
        }
    }, [vkycData?.data, vkycData?.devicePermissionStatus?.status]);

    useEffect(() => {
        if (vkycData?.devicePermissionStatus?.status) {
            dispatch(getInitiateCall({}));
        }
    }, [vkycData?.devicePermissionStatus?.status]);

    const onInitiateCall = () => {
        initiateRecordingSetup();
    };

    useEffect(() => {
        if (connectionOpen) {
            send(JSON.stringify({ type: 'getAvailableCalls' }));
        }
    }, [connectionOpen]);

    useEffect(() => {
        if (response && typeof response === 'object') {
            const { waiting_calls: waitingCalls } = response || {};
            dispatch(setAvailableCalls({ value: waitingCalls }));
        }
    }, [response]);

    // This useEffect hook is responsible for checking if the browser supports desktop notifications and requesting permission from the user.
    // It also creates a custom notification favicon by modifying the existing favicon image.
    useEffect(() => {
        if (!('Notification' in window)) {
            // If the browser does not support desktop notifications, log an error and set the hasNotificationPermission state to false.
            console.error('Browser does not support desktop notification');
            setHasNotificationPermission(false);
        } else {
            // If the browser supports desktop notifications, request permission from the user.
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    // If the user grants permission, set the hasNotificationPermission state to true.
                    setHasNotificationPermission(true);
                } else {
                    // If the user denies permission, set the hasNotificationPermission state to false.
                    setHasNotificationPermission(false);
                }
            });
        }

        // Create a new image element and load the favicon image.
        const img = document.createElement('img');
        const favicon = document.getElementById('favicon');
        img.src = favicon.href || FAVICON_URL;

        // Once the favicon image is loaded, create a canvas element and draw the modified favicon image on it.
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, img.width, img.height);

            // Draw a red circle on the top right corner of the favicon image.
            context.beginPath();
            context.arc(
                img.width - img.width / 5,
                img.height / 5,
                img.width / 5,
                0,
                2 * Math.PI,
            );
            context.fillStyle = '#f00000';
            context.fill();

            // Convert the modified favicon image to a data URL and set it as the notificationFavicon state.
            setNotificationFavicon(canvas.toDataURL('image/png'));
        };
    }, []);

    useEffect(() => {
        if (availableCallCount > 0) {
            if (hasNotificationPermission) {
                const notif = new Notification('New VKYC Call', {
                    body: 'New user available for onboarding',
                });
                notif.onclick = () => {
                    window.parent.parent.focus();
                    notif.close();
                };
            }
            document.getElementById('favicon').href = notificationFavicon;
            document.title = `(${availableCallCount}) New Call:${document.title}`;
            setModalVisible(true);
        } else {
            document.getElementById('favicon').href = FAVICON_URL;
            document.title = 'Sherlock';
            setModalVisible(false);
        }

        // function for resetting the favicon and document title when the component is unmounted.
        return () => {
            document.getElementById('favicon').href = FAVICON_URL;
            document.title = 'Sherlock';
        };
    }, [availableCallCount]);

    return (
        <div className='vkyc-call-queue-screen'>
            {/* <div className='vkyc-call-queue-message-container'>
                <img src={InformationSVG} alt='' className='vkyc-call-queue-message-icon' />
                <div>As soon as customers initiate Video KYC on the app, they will be assigned to you.</div>
            </div> */}
            <Button
                primary
                v2
                label='Initiate Call'
                onClick={onInitiateCall}
                disabled={vkycData?.loading || vkycData?.data?.meetingId || availableCallCount === 0}
            />
            <LoaderOrError loading={vkycData?.loading} errorLabel={vkycData?.error || socketError?.message} />
            <Modal
                visible={modalVisible}
            >
                <div className='fccWrapper'>
                    <h1><strong>New VKYC Call</strong></h1>
                    <p>New user available for onboarding</p>
                    <Button
                        primary
                        v2
                        label='Initiate Call'
                        onClick={() => {
                            setModalVisible(false);
                            onInitiateCall();
                        }}
                    />
                </div>
            </Modal>

        </div>
    );
};

export default CallQueueScreen;
