/**
 * @file UserRiskReview constants
 */

import { INITIAL_DATE_RANGE, RISK_OPS_VIEW_ALL_CASES, SORT_DD_OG_STATE } from '../RiskOps/constants';

// container key used for reducer and saga
export const USER_RISK_REVIEW_KEY = 'userRiskReview';

// action constants
export const GET_USER_RISK_REVIEW_DATA = `app/${USER_RISK_REVIEW_KEY}/GET_USER_RISK_REVIEW_DATA`;
export const SET_USER_RISK_REVIEW_DATA = `app/${USER_RISK_REVIEW_KEY}/SET_USER_RISK_REVIEW_DATA`;
export const RESET_USER_RISK_REVIEW_DATA = `app/${USER_RISK_REVIEW_KEY}/RESET_USER_RISK_REVIEW_DATA`;
export const ERROR_USER_RISK_REVIEW = `app/${USER_RISK_REVIEW_KEY}/ERROR_USER_RISK_REVIEW`;
export const HIDE_LOADER_USER_RISK_REVIEW = `app/${USER_RISK_REVIEW_KEY}/HIDE_LOADER_USER_RISK_REVIEW`;
export const GET_MEDIA_FOR_USER_RISK_REVIEW = `app/${USER_RISK_REVIEW_KEY}/GET_MEDIA_FOR_USER_RISK_REVIEW`;
export const GET_FORM_FIELDS = `app/${USER_RISK_REVIEW_KEY}/GET_FORM_FIELDS`;
export const SET_FORM_FIELDS = `app/${USER_RISK_REVIEW_KEY}/SET_FORM_FIELDS`;
export const SUBMIT_ANNOTATION = `app/${USER_RISK_REVIEW_KEY}/SUBMIT_ANNOTATION`;
export const ERR_SUBMIT_ANNOTATION = `app/${USER_RISK_REVIEW_KEY}/ERR_SUBMIT_ANNOTATION`;
export const GET_USER_RISK_SCREENER_CHECK_DETAILS = `app/${USER_RISK_REVIEW_KEY}/GET_USER_RISK_SCREENER_CHECK_DETAILS`;
export const SET_USER_RISK_SCREENER_CHECK_DETAILS = `app/${USER_RISK_REVIEW_KEY}/SET_USER_RISK_SCREENER_CHECK_DETAILS`;
export const ERR_USER_RISK_SCREENER_CHECK_DETAILS = `app/${USER_RISK_REVIEW_KEY}/ERR_USER_RISK_SCREENER_CHECK_DETAILS`;
export const GET_LINKED_IN_DETAILS = `app/${USER_RISK_REVIEW_KEY}/GET_LINKED_IN_DETAILS`;
export const SET_LINKED_IN_URL = `app/${USER_RISK_REVIEW_KEY}/SET_LINKED_IN_URL`;

// risk ops related constants
const PAYLOAD_TYPE_USER_REVIEW = 'PAYLOAD_TYPE_USER_REVIEW';

// risk screener check details popup name
export const SCREEN_NAME_RISK_SCREENER_ADDITIONAL_DETAILS_POPUP = 'SCREEN_NAME_RISK_SCREENER_ADDITIONAL_DETAILS_POPUP';

const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_USER_REVIEW,
    isVisible: false,
};

export const USER_RISK_REVIEW_REDUCER_STATE = {
    casesList: [],
    curCase: {},
    curView: RISK_OPS_VIEW_ALL_CASES,
    loading: false,
    err: '',
    queueFilters: {
        fromDate: INITIAL_DATE_RANGE.fromDate,
        toDate: INITIAL_DATE_RANGE.toDate,
        sortOption: SORT_DD_OG_STATE,
    },
    payloadType: PAYLOAD_DD_OG_STATE,
};

export const QUEUE_PAYLOAD_TYPES = [PAYLOAD_TYPE_USER_REVIEW];

// backend constants
export const MEDIA_TYPE_IMAGE = 'MEDIA_TYPE_IMAGE';
export const MEDIA_TYPE_VIDEO = 'MEDIA_TYPE_VIDEO';

export const PARAMETER_FILTERS_CONFIG = {
    cardTitle: 'Select Actions to perform',
    paramsTitle: 'Search Parameters',
    defaultIsExpanded: true,
    collapseOnSubmit: false,
    warningModal: {
        required: true,
        message: 'Are you sure you want to perform this action?',
        description: 'This change is irreversible and affects production data',
    },
};

export const MARK_LIVENESS_PASSED = 'MARK_LIVENESS_PASSED';
export const MARK_FACEMATCH_PASSED = 'MARK_FACEMATCH_PASSED';
export const REVIEW_ENTITY_TYPE_LIVENESS = 'REVIEW_ENTITY_TYPE_LIVENESS';
export const REVIEW_ENTITY_TYPE_FACEMATCH = 'REVIEW_ENTITY_TYPE_FACEMATCH';

export const MARK_LIVENESS_PASSED_CONFIG = {
    id: MARK_LIVENESS_PASSED,
    parameterList: [
        /* {
            label: 'Actor ID',
            name: 'actor_id',
            type: 1,
            isOptional: false,
            isDisabled: true,
        },
        {
            label: 'Request ID',
            name: 'request_id',
            type: 1,
            isOptional: false,
            isDisabled: true,
        },
        {
            label: 'Review verdict',
            name: 'review_verdict',
            type: 5,
            options: [],
            annotationType: 'LIVENESS_ANNOTATION_TYPE_REVIEW_VERDICT',
            isOptional: true,
        }, */
        {
            label: 'Video Issues',
            name: 'video_issues',
            type: 9,
            options: [],
            annotationType: 'LIVENESS_ANNOTATION_TYPE_VIDEO_ISSUES',
            isOptional: true,
            disableModifyOptions: true,
        },
        {
            label: 'Live Person',
            name: 'live_person',
            type: 5,
            options: [],
            annotationType: 'LIVENESS_ANNOTATION_TYPE_LIVE_PERSON',
            isOptional: true,
        },
        /* {
            label: 'Potential Fraud',
            name: 'potential_fraud',
            type: 5,
            options: [],
            annotationType: 'LIVENESS_ANNOTATION_TYPE_POTENTIAL_FRAUD',
            isOptional: true,
        },
        {
            label: 'Remarks',
            name: 'remarks',
            type: 1,
            isOptional: true,
        },
        {
            label: 'Reviewed By (ex: yourname@epifi.com)',
            name: 'reviewed_by',
            type: 1,
            isOptional: true,
            isDisabled: true,
        },
        {
            label: 'User face shown',
            name: 'user_face_shown',
            type: 5,
            annotationType: 'LIVENESS_ANNOTATION_TYPE_USER_FACE_SHOWN',
            options: [],
            isOptional: true,
        }, */
        {
            label: 'Reason',
            name: 'reason',
            type: 1,
            isOptional: true,
        },
    ],
};

export const MARK_FACEMATCH_PASSED_CONFIG = {
    id: MARK_FACEMATCH_PASSED,
    parameterList: [
        /* {
            label: 'Actor ID',
            name: 'actor_id',
            type: 1,
            isOptional: false,
            isDisabled: true,
        },
        {
            label: 'Request ID',
            name: 'request_id',
            type: 1,
            isOptional: false,
            isDisabled: true,
        },
        {
            label: 'Review verdict',
            name: 'review_verdict',
            type: 5,
            options: [],
            annotationType: 'FACE_MATCH_ANNOTATION_TYPE_REVIEW_VERDICT',
            isOptional: true,
         }, */
        {
            label: 'FaceMatch Issues',
            name: 'facematch_issues',
            type: 9,
            options: [],
            annotationType: 'FACE_MATCH_ANNOTATION_TYPE_FACEMATCH_ISSUES',
            isOptional: true,
            disableModifyOptions: true,
        },
        /* {
        //     label: 'Reviewed By (ex: yourname@epifi.com)',
        //     name: 'reviewed_by',
        //     type: 1,
        //     isOptional: true,
        //     isDisabled: true,
        // },
        // {
        //     label: 'Remarks',
        //     name: 'remarks',
        //     type: 1,
        //     isOptional: true,
         }, */
        {
            label: 'Reason',
            name: 'reason',
            type: 1,
            isOptional: true,
        },
    ],
};

export const MARK_ACTION_FORM = [MARK_LIVENESS_PASSED_CONFIG, MARK_FACEMATCH_PASSED_CONFIG];

export const TABLE_TITLES_WITH_V2_ROW = [
    'LEA History Data',
];
