/**
 *
 * DocumentReview:
 * Review a single Document Redaction case for payload type PAYLOAD_TYPE_STOCKGUARDIAN_CKYC_DOCUMENTS & check the following:
 * 1. Check if images are masked correctly
 *
 */
import React, { memo, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { downloadBlobFromUrl } from '../../../utils/downloadFile';
import {
    ExpandableCard, RenderDocument, DisableContextMenu, Button,
    FormDropdown,
} from '../../../components';
import { onFileChange, onFileClick } from '../../../utils';

import { CUSTOM_FILE_TYPES_MAPPING, DOCUMENT_REDACTION_STATUS_OPTIONS, ERR_DOC_REDACTION, SUPPORTED_FILE_TYPES } from '../constants';
import { selectDocRedactionCurCase } from '../selectors';
import { executeDocRedactionAction } from '../actions';

const DocumentReview = () => {
    const dispatch = useDispatch();

    const [fileUploadErrLabel, setFileUploadErrLabel] = useState([]);
    const [fileContent, setFileContent] = useState([]);
    const [isDocumentCardExpanded, setDocumentCardExpanded] = useState(true);
    const [documentRedactionStatus, setDocumentRedactionStatus] = useState([]);

    const hiddenFileInput = useRef([]);

    const curCase = useSelector(selectDocRedactionCurCase(), shallowEqual);

    // Extract information from the case
    const { documents, ckyc_reference_id: referenceId, application_id: applicationId } = curCase?.stock_guardian_ckyc_documents || {};

    /**
     * Downloads document images by constructing the file format and name
     * based on the document type and then using a utility function to download.
     * @param {Object} document - The document object containing type, url, and file name.
     */
    const downloadDocImgs = (document) => {
        const { type: documentType, url, file_name: fileName } = document || {};
        const formattedDocType = CUSTOM_FILE_TYPES_MAPPING[documentType] && CUSTOM_FILE_TYPES_MAPPING[documentType].toLowerCase();
        let fileFormat = `image/${formattedDocType}`;

        if (formattedDocType === 'pdf') fileFormat = `application/${formattedDocType}`;

        downloadBlobFromUrl(`${fileName}.${formattedDocType}`, fileFormat, url);
    };

    /**
     * Effect hook that initializes state arrays for file content, document redaction status,
     * and file upload error labels based on the number of documents.
     */
    useEffect(() => {
        if (documents && Array.isArray(documents) && documents.length > 0) {
            // Create an array filled with null values, with the same length as the documents array
            const nullArr = new Array(documents.length).fill(null);

            // Initialize fileContent state with null values if it's not already set or if its length doesn't match the documents array
            if (!fileContent || fileContent.length !== documents.length) setFileContent(nullArr);

            // Initialize documentRedactionStatus state with null values if it's not already set or if its length doesn't match the documents array
            if (!documentRedactionStatus || documentRedactionStatus.length !== documents.length) setDocumentRedactionStatus(nullArr);

            // Initialize fileUploadErrLabel state with null values if it's not already set or if its length doesn't match the documents array
            if (!fileUploadErrLabel || fileUploadErrLabel.length !== documents.length) setFileUploadErrLabel(nullArr);
        }
    }, [documents]);

    /**
     * Updates a specific index in a state array with a new value.
     * @param {number} index - The index in the array to update.
     * @param {*} newValue - The new value to set at the specified index.
     * @returns {function} - A function that takes the previous state array and returns a new array with the updated value.
     */
    const handleStateValueUpdate = (index, newValue) => (prevValueArray) => {
        const prevValue = prevValueArray[index];
        // Check if the previous value exists or is explicitly null
        if (prevValue || prevValue === null) {
            // Create a new array with the updated value at the specified index
            const newValueArray = [...prevValueArray];
            newValueArray[index] = newValue;
            return newValueArray;
        }
        // Return the previous array if no update is needed
        return prevValueArray;
    };

    /**
     * Returns an object with methods to set error labels and file content
     * for a specific document index.
     * @param {number} index - The index of the document in the list.
     * @returns {Object} - An object with setErrLabel and setValue methods.
     */
    const fileParams = (index) => ({
        /**
         * Sets the error label for a specific document index.
         * @param {string} errLabel - The error label to set.
         */
        setErrLabel: (errLabel) => {
            setFileUploadErrLabel(handleStateValueUpdate(index, errLabel));
        },
        /**
         * Sets the file content for a specific document index.
         * @param {Object} file - The file object to set.
         */
        setValue: (file) => {
            setFileContent(handleStateValueUpdate(index, file));
        },
    });

    // Return null if there are no documents to process.
    if (!(documents && Array.isArray(documents) && documents.length > 0)) return null;

    return (
        <div className='fccWrapper'>
            <ExpandableCard
                extClasses='doc-redac-ec my-30 p-30'
                extHeadingClasses='p-0'
                heading='Document Redaction Review'
                isExpanded={isDocumentCardExpanded}
                setIsExpanded={() => {
                    setDocumentCardExpanded(!isDocumentCardExpanded);
                }}
            >

                <DisableContextMenu>
                    {
                        documents.map(({ type: documentType, url, file_name: fileName }, docIndex) => (
                            <React.Fragment>
                                <div className='doc-redac-hl2 mt-30'>{fileName}</div>
                                <div className='doc-redac-img my-30'>
                                    <RenderDocument
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={docIndex}
                                        disableToolbar
                                        data={{
                                            srcUrl: url,
                                            docType: CUSTOM_FILE_TYPES_MAPPING[documentType],
                                        }}
                                    />
                                </div>
                                <div className='frfssbWrapper my-30' style={{ width: '100%' }}>
                                    <Button
                                        v2
                                        primary
                                        label='Download'
                                        onClick={() => downloadDocImgs({ type: documentType, url, file_name: fileName })}
                                    />
                                    <FormDropdown
                                        options={DOCUMENT_REDACTION_STATUS_OPTIONS}
                                        input={{
                                            value: documentRedactionStatus[docIndex],
                                            onChange: (newValue) => {
                                                setDocumentRedactionStatus((prevDocumentRedactionStatus) => {
                                                    const prevStatus = prevDocumentRedactionStatus[docIndex];
                                                    if (prevStatus || prevStatus === null) {
                                                        const newDocumentRedactionStatus = [...prevDocumentRedactionStatus];
                                                        newDocumentRedactionStatus[docIndex] = newValue;
                                                        return newDocumentRedactionStatus;
                                                    }
                                                    return prevDocumentRedactionStatus;
                                                });
                                            },
                                        }}
                                        cacheKey='document-redaction-status'
                                        extStyles={{
                                            container: 'doc-redac-dd',
                                        }}
                                    />
                                    <div className='fccWrapper doc-redac-btn__cr'>
                                        <Button
                                            v2
                                            secondary
                                            extClasses='doc-redac-btn'
                                            label='Upload'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (hiddenFileInput.current[docIndex]) hiddenFileInput.current[docIndex].click();
                                            }}
                                        />
                                        <input
                                            hidden
                                            ref={(ref) => {
                                                if (hiddenFileInput.current) hiddenFileInput.current[docIndex] = ref;
                                            }}
                                            type='file'
                                            accept={SUPPORTED_FILE_TYPES}
                                            onClick={onFileClick(fileParams(docIndex))}
                                            onChange={onFileChange(fileParams(docIndex))}
                                        />
                                        {
                                            fileContent && Array.isArray(fileContent) && fileContent.length > 0 && fileContent[docIndex] ? (
                                                <div>{`${fileContent[docIndex]?.fileName} file selected`}</div>
                                            ) : (
                                                <div>No files selected</div>
                                            )
                                        }
                                        {
                                            fileUploadErrLabel[docIndex] ? (
                                                <div className='err-label devactions-fuel'>{fileUploadErrLabel[docIndex]}</div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </DisableContextMenu>
                <div className='frccWrapper'>
                    <Button
                        v2
                        primary
                        label='Submit'
                        onClick={() => {
                            // Check if every document has a redaction status set
                            const allDocsHaveStatus = documentRedactionStatus.every((status) => status !== null);

                            // If any document is missing a status, dispatch an error action and exit
                            if (!allDocsHaveStatus) {
                                dispatch({ type: ERR_DOC_REDACTION, data: { err: 'all documents should have a redaction document status' } });
                                return;
                            }

                            // Prepare the payload for the redaction action
                            const payload = {
                                referenceId, // Reference ID for the current case
                                applicationId, // Application ID for the current case
                                redactedDocuments: [], // Initialize an empty array for redacted documents
                            };

                            // Iterate over each document to construct the redacted documents payload
                            documents.forEach((doc, index) => {
                                payload.redactedDocuments.push({
                                    redactionStatus: documentRedactionStatus?.[index]?.value, // Set the redaction status for the document
                                    file: {
                                        ...doc, // Spread the document properties
                                        base64_data: fileContent?.[index]?.contentBase64, // Add the base64 content of the file
                                    },
                                });
                            });

                            // Dispatch the action to execute the document redaction with the constructed payload
                            dispatch(executeDocRedactionAction(payload));
                        }}
                    />
                </div>

            </ExpandableCard>
        </div>
    );
};

export default memo(DocumentReview);
