/**
 *
 * View list of Document Redaction cases
 * Show a table of cases with details about what the user needs to verify like document redaction review.
 * User can click a particular row and view further information
 *
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { isObjEmpty } from '../../../utils/helpers';
import { AppDropdown, Button, Table } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import {
    makeSelectDocRedactionQueueCases, selectDocRedactionQueueFilters,
} from '../selectors';
import {
    VIEW_SINGLE_DOC_REDACTION_CASE, LIMIT_OPTIONS,
    DOC_REDACTION_ALL_CASE_TABLE_LABEL_DATA,
} from '../constants';
import {
    setDocRedactionCurCaseAction, setDocRedactionCurViewAction, setDocRedactionQueueFiltersAction, getPresignedDocumentUrl,
} from '../actions';

const AllCases = () => {
    const dispatch = useDispatch();

    // global state
    const queueCases = useMemoizedSelector(makeSelectDocRedactionQueueCases);
    const queueFilters = useSelector(selectDocRedactionQueueFilters(), shallowEqual);
    const callAction = (dispatchFunction) => new Promise((resolve, reject) => {
        try {
            dispatchFunction(resolve, reject);
        } catch (e) {
            reject(e);
        }
    });

    const { limit } = queueFilters;

    const getModifiedcase = (currentCase, redactedDocUrls) => (
        currentCase.stock_guardian_ckyc_documents.documents.map((doc) => {
            const redactedDocUrl = redactedDocUrls[doc.file_name];
            return {
                ...doc,
                url: redactedDocUrl,
            };
        })
    );

    const handleReviewClick = (curCase) => () => {
        const documentsMap = {};
        curCase.stock_guardian_ckyc_documents.documents.forEach((doc) => {
            documentsMap[doc?.file_name] = doc?.url;
        });
        callAction((resolve, reject) => dispatch(getPresignedDocumentUrl({
            documentsMap, resolve, reject,
        })))
            .then((response = {}) => {
                const { redactedDocUrls = {} } = response;
                const modifiedCurCase = {
                    ...curCase,
                    stock_guardian_ckyc_documents: {
                        ...curCase.stock_guardian_ckyc_documents,
                        documents: getModifiedcase(curCase, redactedDocUrls),
                    },
                };
                dispatch(setDocRedactionCurCaseAction(modifiedCurCase));
                if (!isObjEmpty(modifiedCurCase)) dispatch(setDocRedactionCurViewAction(VIEW_SINGLE_DOC_REDACTION_CASE));
            })
            .catch((error) => {
                console.error('Error while fetching redacted document urls', error);
            });
    };

    const setLimit = (newLimit) => {
        dispatch(setDocRedactionQueueFiltersAction({ key: 'limit', value: newLimit }));
    };

    const renderActions = (item, index) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='Review'
                onClick={handleReviewClick({
                    ...item.ogData,
                    index,
                })}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div className='frfssbWrapper'>
                <div className='frcWrapper'>
                    <div className='doc-redac-idd__lb'>Queue Size</div>
                    <AppDropdown
                        ddState={limit}
                        setDDState={setLimit}
                        ddList={LIMIT_OPTIONS}
                        defaultDDLabel='Select Queue Size'
                        ddItemCls='custom-dd__cl doc-redac-idd__con'
                        ddWrapperCls={{
                            container: 'custom-dd doc-redac-idd1',
                        }}
                        ddContentCls={{
                            container: ['doc-redac-idd__cr1'],
                        }}
                    />
                </div>
            </div>

            <Table
                v2
                labelData={DOC_REDACTION_ALL_CASE_TABLE_LABEL_DATA}
                contentData={queueCases}
                renderActionableColumn={renderActions}
                extClasses={{
                    container: 'doc-redac-src',
                }}
            />
        </React.Fragment>
    );
};

export default memo(AllCases);
