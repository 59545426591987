import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
    clientApiWrapper, getErrLabel, toastify,
} from '../../utils';
import { INITIATE_AUDIT, FETCH_AUDIT_REPORT, REVIEW_AUDITOR_REVIEW, GET_AVAILABLE_AUDIT_REPORT_COUNT } from '../../api/routes';
import {
    AUDITOR_SCREEN_TYPE, FETCH_AUDIT_CASE, FETCH_REVIEW_COUNT, REVIEW_REPORT, SET_CASE_COUNT,
    GET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT, SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT,
} from './constants';
import {
    setAuditReport,
} from './actions';

export const WS_URL = `ws://${window.location.host}`;

function* fetchAuditCases() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            INITIATE_AUDIT,
        );
        const {
            screen_recording_url: screenRecordingUrl, report, meeting_id: meetingId,
            rejection_categories: rejectionReasons,
        } = response;
        yield put(setAuditReport({
            recordingUrl: screenRecordingUrl, report, meetingId, screenKey: AUDITOR_SCREEN_TYPE.REVIEW_SCREEN, rejectionReasons,
        }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setAuditReport({
            recordingUrl: null, report: null, meetingId: null, screenKey: AUDITOR_SCREEN_TYPE.INITIATE_SCREEN,
        }));
    }
}

function* getReviewCasesCount() {
    try {
        const { waiting_reports: waitingReports } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            FETCH_AUDIT_REPORT,
        );
        yield put(SET_CASE_COUNT({ waitingReports }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(SET_CASE_COUNT({ waitingReports: 0 }));
    }
}

function* reviewReport(action) {
    const {
        meetingId, isApproved, rejectionReason, remarks,
    } = action.data;

    const requestBody = {
        meetingId, isApproved, rejectionReason, remarks,
    };

    try {
        if (!meetingId) {
            throw new Error('Unable to process request, please contact admin');
        }
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            REVIEW_AUDITOR_REVIEW,
            requestBody,
        );
        yield put(setAuditReport({
            recordingUrl: null, report: null, meetingId: null, screenKey: AUDITOR_SCREEN_TYPE.FINAL_SCREEN, rejectionReasons: [],
        }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }
}

function* getAvailableAuditorReviewCount() {
    try {
        const { waiting_reports: waitingReports } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            GET_AVAILABLE_AUDIT_REPORT_COUNT,
        );
        yield put({ type: SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT, data: { waitingReports } });
    } catch (e) {
        yield put({ type: SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT, data: 0 });
    }
}

export default function* ScriptsSaga() {
    yield all(
        [
            yield takeLatest(FETCH_AUDIT_CASE, fetchAuditCases),
            yield takeLatest(FETCH_REVIEW_COUNT, getReviewCasesCount),
            yield takeLatest(REVIEW_REPORT, reviewReport),
            yield takeLatest(GET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT, getAvailableAuditorReviewCount),
        ],
    );
}
