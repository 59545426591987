/**
 * Routes/index.js - Defines all the routes needed for layout from the access level that is being accessed
 */

import React, { useContext } from 'react';
import {
    Route, Switch, Redirect,
} from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import PhoneSVG from '../../../assets/images/svgs/round-phone.svg';
import { maskPhoneNumber } from '../../../utils/formatter';
import { MetaInfoContext, UserDetailsContext } from '../../../context';

import { selectCallerId } from '../../SOP/selector';

import { ROLES_FOR_NORMAL_SHERLOCK_V2, ROLE_TO_NAVBAR_MAPPING } from '../utils/pathsHelper';

const Routes = () => {
    const { selectedAccessLevel } = useContext(UserDetailsContext);
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            callHandlingV2,
        },
    } = metaInfo;
    const callerId = useSelector(selectCallerId, shallowEqual);

    return (
        <div>
            {
                ROLES_FOR_NORMAL_SHERLOCK_V2[selectedAccessLevel] && callerId && callHandlingV2 === 1 && (
                    <div className='app__v2-header p-3'>
                        <div className='frcsbWrapper app__v2-header-box'>
                            <div className='frcWrapper'>
                                <img src={PhoneSVG} alt='Phone' className='app__v2-header-img mr-16' />
                                <div className='app__v2-header-title'>Call From {maskPhoneNumber(callerId)}</div>
                            </div>
                            <div><span className='app__v2-dot' />On Call</div>
                        </div>
                    </div>
                )
            }
            <Switch>
                {Object.keys(ROLE_TO_NAVBAR_MAPPING?.[selectedAccessLevel] || [])?.map((NavbarKey) => (
                    <Route
                        path={ROLE_TO_NAVBAR_MAPPING[selectedAccessLevel]?.[NavbarKey]?.path}
                        component={ROLE_TO_NAVBAR_MAPPING[selectedAccessLevel]?.[NavbarKey]?.component}
                        key={ROLE_TO_NAVBAR_MAPPING[selectedAccessLevel]?.[NavbarKey]?.path}
                    />
                ))}
                {
                    ROLE_TO_NAVBAR_MAPPING?.[selectedAccessLevel]
                    && <Redirect to={ROLE_TO_NAVBAR_MAPPING[selectedAccessLevel]?.DEFAULT?.path} />
                }
            </Switch>
        </div>
    );
};

export default Routes;
