import React from 'react';
import Modal from 'react-modal';
import Button from '../../../components/Button';

// Custom styles for the modal
const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1024,
    },
    content: {
        top: '8%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 32px 24px',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: '80%',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '80%',
        backgroundColor: '#ECEEF0',
        borderRadius: 19,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
};

const ApprovalRemarks = (props) => {
    const {
        setApprovalRemarkState, approvalRemarkState, approvalReviewReportAction,
    } = props;

    const { visible } = approvalRemarkState;

    return (

        <Modal
            isOpen={visible}
            style={customStylesForModal}
            contentLabel='Add Remarks'
        >
            <div className='vkyc-remarks-text-container'>
                <div className='vkyc-remarks-field-label' />
                <textarea
                    className='vkyc-remarks-text-container-textarea'
                    onChange={(event) => setApprovalRemarkState((prev) => ({
                        ...prev,
                        comment: event?.target?.value,
                    }))}
                    value={approvalRemarkState.comment}
                    placeholder='Enter your remarks here'
                />
            </div>

            <div className='vkyc-approval-remarks'>
                <Button
                    v2
                    secondary
                    label='Cancel'
                    onClick={() => setApprovalRemarkState((prev) => ({ ...prev, visible: false }))}
                />
                <Button
                    primary
                    v2
                    label='Approve'
                    onClick={approvalReviewReportAction}
                />
            </div>
        </Modal>

    );
};

export default ApprovalRemarks;
