/**
 * @file Manages the dynamic config consul Meta Info Context API functions
 */

import React from 'react';

// initial values for consul meta info
export const META_INFO_INITIAL_VALUES = {
    appConfig: {
        enableAgentManagementV2: true,
        enableAgentManagementMaintenanceMode: false,
        enableSingleTicketCreationFlow: true,
        enableDirectRedirectionToSherlockV2: true,
    },
    riskOps: {
        downloadMedia: {
            allowDownloadEvenIfTrackingApiFails: false,
            allowedEmails: [],
        },
        transactionViewVersion: 2,
        userProducts: 1,
        transactionViewV2Params: {
            lifetimeCreditDebitTrend: {
                enable: true,
                isExpanded: true,
            },
            creditDebitTrends: {
                enable: true,
                isExpanded: true,
                creditDebitGraph: {
                    enable: true,
                },
                creditSplitCategoryGraph: {
                    enable: true,
                },
                distinctSenderReceiverGraph: {
                    enable: true,
                },
            },
            keyTransactionTrends: {
                enable: true,
                isExpanded: true,
                protocolGraph: {
                    enable: true,
                },
                provenanceGraph: {
                    enable: true,
                },
                p2pP2mGraph: {
                    enable: true,
                },
            },
            transactionHistoryTable: {
                enable: true,
                isExpanded: true,
            },
        },
    },
    pageVersions: {
        creditCard: 2,
        sendNotifications: 1,
    },
    componentVersions: {
        livenessReview: 2,
        facematchReview: 2,
        banners: 2,
        freshdeskIssueInfo: 1,
        txnDispute: 1,
        disputeFlowVersion: 2,
        recentActivityVersion: 1,
        userSpecificBanners: 1,
        autoPayVersion: 1,
        verificationQueueVersion: 2,
        scriptsSearchVersion: 1,
        vkycConfirmation: 1,
        callHandlingV2: 1,
    },
    payouts: {
        cashPayoutRoles: ['ADMIN', 'SUPER_ADMIN'],
        fiCoinsPayoutRoles: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
    },
    tickets: {
        reasonInfo: {
            enableReasonFlow: true,
            callListTable: [
                {
                    label: 'Issue in attaching to existing ticket ID',
                    value: 'Issue in attaching to existing ticket ID',
                    id: 'issue-in-existing-ticket',
                },
                {
                    label: 'User does not have an existing ticket for this issue',
                    value: 'User does not have an existing ticket for this issue',
                    id: 'no-existing-ticket',
                },
                {
                    label: 'Unable to find user\'s reported issue in the listed tickets',
                    value: 'Unable to find user\'s reported issue in the listed tickets',
                    id: 'unable-to-find-issue',
                },
            ],
            createNewCtaLanding: [
                {
                    label: 'Issue in attaching to existing ticket ID',
                    value: 'Issue in attaching to existing ticket ID',
                    id: 'issue-in-existing-ticket',
                },
                {
                    label: 'User does not have an existing ticket for this issue',
                    value: 'User does not have an existing ticket for this issue',
                    id: 'no-existing-ticket',
                },
                {
                    label: 'Unable to find user\'s reported issue in the listed tickets',
                    value: 'Unable to find user\'s reported issue in the listed tickets',
                    id: 'unable-to-find-issue',
                },
            ],
        },
    },
    vkycConfig: {
        defaultEnabledStages: [],
    },
    imageReloadConfig: {
        maxRetries: 3,
        retryDelay: 1000,
    },
};

const MetaInfoContext = React.createContext(META_INFO_INITIAL_VALUES);

export default MetaInfoContext;
