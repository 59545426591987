/**
 * @file BarChartWrapper component
 *
 * This file contains the BarChartWrapper component which is responsible for rendering
 * a BarChart with custom scales and aggregated data. It uses the useBarCustomXScale hook
 * to manage the x-axis scale and zoom transformations.
 */
import React, { useMemo } from 'react';
import BarChart from '../../../components/d3/BarChartV2';
import { useBarCustomXScale } from '../../../components/d3/useBarXScale';
import { DAY, MONTH, WEEK } from '../../../components/d3/constants';
import { staticColorMapping, zoomLevelEnum } from '../constants';
import { aggregatedDataGrouper } from '../utils';

const convertScaleEnumToAggregationValue = (scale) => {
    switch (scale) {
        case zoomLevelEnum.Day:
            return DAY;
        case zoomLevelEnum.Week:
            return WEEK;
        case zoomLevelEnum.Month:
            return MONTH;
        default:
            return null;
    }
};

const BarChartWrapper = ({
    data, aggregation, dimensions, yValue, onboardingDate,
}) => {
    const aggregationScale = convertScaleEnumToAggregationValue(data?.xZoomLevel) || aggregation;
    const {
        xScale, setXScale, xScaleTemplate, zoomTransform, setZoomTransform,
    } = useBarCustomXScale({
        aggregation: aggregationScale,
        dimensions,
        data: (data || {}).dataPoints || [],
    });

    const aggregatedData = useMemo(
        () => aggregatedDataGrouper((data || {}).dataPoints, aggregationScale, 'type'),
        [aggregationScale, data],
    );

    return (
        <BarChart
            enableGraphTypeDropdown={false}
            aggregation={aggregationScale}
            yValue={yValue}
            dimensions={dimensions}
            data={aggregatedData}
            bars={data.bars}
            legendPlacement='bottom'
            headingPlacement='top'
            heading={data.title}
            xScale={xScale}
            setXScale={setXScale}
            xScaleTemplate={xScaleTemplate}
            zoomTransform={zoomTransform}
            setZoomTransform={setZoomTransform}
            onboardingDate={onboardingDate}
            graphTypeValue='GROUPED'
            horizontalRefLines={data.xReferenceLines}
            xRefs={data.xRefs}
            barsCount={data.barCount}
            staticColorMapping={staticColorMapping}
        />
    );
};

export default BarChartWrapper;
