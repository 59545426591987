/**
 * @file DocRedaction - This component shows the cases pending in the queue for manual verification
 * For each case, the agent may need to verify some or all the following:
 * 1. Image of the document proof provided. Check if sensitive information is masked correctly.
 *    If not masked correctly, edit the image and mask the details properly.
 */

import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';
import { LoaderOrError } from '../../components';

import { VIEW_ALL_DOC_REDACTION_CASES, VIEW_SINGLE_DOC_REDACTION_CASE, DOC_REDACTION_KEY } from './constants';
import {
    selectDocRedactionCurView, selectDocRedactionErr, selectDocRedactionLoading, selectDocRedactionPayloadType, selectDocRedactionQueueFilters,
} from './selectors';
import { getDocRedactionQueueCasesAction } from './actions';
import AllCases from './AllCases';
import SingleCase from './SingleCase';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const RenderView = ({ curView }) => {
    switch (curView) {
        case VIEW_ALL_DOC_REDACTION_CASES: {
            return <AllCases />;
        }

        case VIEW_SINGLE_DOC_REDACTION_CASE: {
            return <SingleCase />;
        }

        default:
            return null;
    }
};

const DocRedaction = () => {
    const dispatch = useDispatch();

    // global state
    const loading = useSelector(selectDocRedactionLoading(), shallowEqual);
    const err = useSelector(selectDocRedactionErr(), shallowEqual);
    const curView = useSelector(selectDocRedactionCurView(), shallowEqual);
    const payloadType = useSelector(selectDocRedactionPayloadType(), shallowEqual);
    const queueFilters = useSelector(selectDocRedactionQueueFilters(), shallowEqual);

    const { limit } = queueFilters;

    useEffect(() => {
        dispatch(getDocRedactionQueueCasesAction({
            payloadType: payloadType.value,
            limit: limit.value,
        }));
    }, [payloadType.value, limit.value, dispatch]);

    return (
        <div className='doc-redac-cr'>
            <RenderView curView={curView} />
            <LoaderOrError loading={loading} errorLabel={err} />
        </div>
    );
};

const withReducer = injectReducer({ key: DOC_REDACTION_KEY, reducer });
const withSaga = injectSaga({ key: DOC_REDACTION_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(DocRedaction);
