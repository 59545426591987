import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ERROR_TYPES } from '../../../../components/ErrorView/constants';
import ImageUtils, { AutoScaleImageViewer } from '../../../../utils/imageUtils';
import { Button, ErrorView, ProgressBar, Table } from '../../../../components';

import { dobFormatter, nameFormatter, matchScoreFormatting } from '../../utils/serverCodeFormatter';
import { selectVkycData, selectCurrentSubStage, selectCurrentStage, selectMeetingLoadingValue } from '../../selectors';
import {
    getCaptureScreenshot, getExtractDocumentDetails, getMatchScore, getPerformClientAction, logStageProgress, setCurrentStagePointers,
    setErrorViewAction,
    setMeetingLoaderState,
} from '../../actions';
import {
    CAMERA_ORIENTATION, CLIENT_ACTION, ONBOARIDNG_STAGE_TYPE, VKYCDocumentType, stagePointerPayload,
} from '../../constants';

const PassportCaptureStep = (props) => {
    const { data, meetingId, setAllAnsweredYes } = props;

    const [isImageClear, setIsImageClear] = useState();
    const [imageOrientations, setImageOrientations] = useState({});

    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData, shallowEqual);

    const subStageStep = useSelector(selectCurrentSubStage(meetingId));
    const currentStepNumber = useSelector(selectCurrentStage(meetingId));
    const meetingLoadingState = useSelector(selectMeetingLoadingValue(meetingId));

    const { stages } = vkycData?.meetings?.[meetingId] || {};

    const setSubStageStep = (stepNumber) => {
        dispatch(logStageProgress({
            meetingId,
            message: stepNumber < subStageStep ? 'Stage reset' : 'Stage completed',
            stageName: stages?.[currentStepNumber]?.stage_name,
            subStageType: stages?.[currentStepNumber]?.stages?.[subStageStep]?.stage_type,
        }));
        dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStepNumber, stepNumber)));
    };

    const handleImageReload = ({
        isLoading, isFinal, errText, url, proxiedUrl,
    }) => {
        if (isLoading) {
            dispatch(logStageProgress({
                meetingId,
                message: 'Image fetch failed. Retrying...',
                stageName: stages?.[currentStepNumber]?.stage_name,
                subStageType: stages?.[currentStepNumber]?.stages?.[subStageStep]?.stage_type,
            }));
            dispatch(setMeetingLoaderState({ meetingId, loading: 'Image fetch failed. Retrying...' }));
        } else {
            if (isFinal) {
                dispatch(logStageProgress({
                    meetingId,
                    url,
                    proxiedUrl,
                    message: `Image fetch failed. ${typeof errText === 'string' ? errText : ''}`,
                    stageName: stages?.[currentStepNumber]?.stage_name,
                    subStageType: stages?.[currentStepNumber]?.stages?.[subStageStep]?.stage_type,
                }));
            }
            dispatch(setMeetingLoaderState({ meetingId, loading: false }));
        }
    };

    const captureOnClick = () => {
        const imageUrl = vkycData?.meetings?.[meetingId]?.screenshot?.imageUrl;
        if (imageUrl) {
            dispatch(getCaptureScreenshot({
                meetingId,
                screenKey: ['meetings', meetingId, 'stages',
                    [vkycData?.meetings?.[meetingId]?.stepNumber], 'stages', subStageStep, data?.stages[subStageStep]?.image_capture?.camera_type],
            }));
        }
        dispatch(getCaptureScreenshot({
            meetingId,
            screenKey: ['meetings', meetingId, 'stages',
                [vkycData?.meetings?.[meetingId]?.stepNumber], 'stages', subStageStep, data?.stages[subStageStep]?.image_capture?.camera_type],
            data: {},
        }));
    };

    const switchCamera = (currentOrientation) => () => {
        dispatch(getPerformClientAction({
            meetingId, clientAction: CLIENT_ACTION.Flip, currentOrientation, screenKey: 'action',
        }));
    };

    const handleFaceMatchSubmit = (answer) => {
        if (answer) {
            setSubStageStep(subStageStep + 1);
        }
    };

    const stepNumber = vkycData?.meetings?.[meetingId]?.stepNumber;

    const switchCameraAndContinue = () => {
        const currentOrientation = vkycData?.meetings?.[meetingId]?.cameraOrientation;
        if (currentOrientation === CAMERA_ORIENTATION.back) {
            switchCamera(CAMERA_ORIENTATION.back)();
        }
    };

    const onClickImageClearYes = () => {
        setIsImageClear(true);
        setSubStageStep(subStageStep + 1);
        const imageObject = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber]?.stages?.[0]?.[CAMERA_ORIENTATION.back];
        dispatch(getExtractDocumentDetails({
            documentType: VKYCDocumentType.PASSPORT,
            imageUrl: imageObject?.imageUrl,
            frontImageIdentifier: imageObject?.imageIdentifier,
            backImageIdentifier: null,
            extractFaceImage: true,
            screenKey: ['meetings', meetingId, 'stages', vkycData?.meetings?.[meetingId]?.stepNumber, 'documentDetails'],
            meetingId,
        }));
        switchCameraAndContinue();
    };

    const currentStepGlobal = data?.stages?.[subStageStep];
    const currentStageGlobal = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber];
    const imgUrlGlobal = currentStageGlobal?.stages?.[subStageStep]?.[currentStepGlobal?.image_capture?.camera_type]?.imageUrl;
    // eslint-disable-next-line max-len
    const passportFrontGlobal = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages?.[stepNumber]?.stages?.[0]?.[CAMERA_ORIENTATION.back]?.imageUrl;

    const getOrientationClass = (url) => {
        if (!url) return false;

        const img = new Image();
        img.src = url;
        img.onload = () => {
            const isHorizontal = img.width > img.height;
            setImageOrientations((prev) => ({ ...prev, [url]: isHorizontal }));
        };

        return imageOrientations[url];
    };

    useEffect(() => {
        getOrientationClass(imgUrlGlobal);
        getOrientationClass(passportFrontGlobal);
    }, [imgUrlGlobal, passportFrontGlobal]);

    useEffect(() => {
        if (vkycData?.errorView && vkycData?.errorView?.type === ERROR_TYPES.toast) {
            setTimeout(() => {
                dispatch(setErrorViewAction({ data: null }));
            }, 1000);
        }
    }, [vkycData?.errorView]);

    const getNextComponent = () => {
        const currentStep = data?.stages?.[subStageStep];

        const currentStage = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber];
        const imgUrl = currentStage?.stages?.[subStageStep]?.[currentStep?.image_capture?.camera_type]?.imageUrl;
        const documentDetails = currentStage?.documentDetails;

        const documentImageOne = currentStage?.stages?.[0]?.[CAMERA_ORIENTATION.back];
        // const documentImageTwo = currentStage?.stages?.[3]?.[CAMERA_ORIENTATION.back];

        const faceImage = vkycData?.meetings?.[meetingId]?.stages?.[2]?.[CAMERA_ORIENTATION.front];
        const passportFront = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber]?.stages?.[0]?.[CAMERA_ORIENTATION.back]?.imageUrl;

        switch (currentStep?.stage_type) {
            case ONBOARIDNG_STAGE_TYPE.IMAGE_CAPTURE:
                if (vkycData?.meetings?.[meetingId]?.cameraOrientation !== currentStep?.image_capture?.camera_type) {
                    return (
                        <div className='vkyc-switch-container'>
                            <div className='vkyc-container-title mb-30'>{currentStep?.image_capture?.instruction}</div>
                            <Button
                                v2
                                primary
                                label='Switch Camera Side'
                                extClasses=''
                                onClick={switchCamera(vkycData?.meetings?.[meetingId]?.cameraOrientation)}
                            />
                        </div>
                    );
                }
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.image_capture?.instruction}</div>
                        <div className='vkyc-image-container'>
                            <AutoScaleImageViewer
                                url={imgUrl}
                                captureOnClick={captureOnClick}
                                baseContainerClass='vkyc-image-placeholder'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal vkyc-image-placeholder-hori-button-height'
                                landscapeImageClass='vkyc-image-horizontal'
                                handleReload={handleImageReload}
                            />
                            {imgUrl && (
                                <div className='ml-30'>
                                    <div className='sop-step-des'>{currentStep?.image_capture?.post_capture_detailed_instruction}</div>
                                    <div className='frWrapper'>
                                        <Button
                                            primary={isImageClear}
                                            secondary={!isImageClear}
                                            v2
                                            label='Yes'
                                            onClick={onClickImageClearYes}
                                            extClasses='vkyc-step-option'
                                        />
                                        <Button
                                            primary={isImageClear === false}
                                            secondary={isImageClear !== false}
                                            v2
                                            label='No'
                                            onClick={() => { setIsImageClear(false); }}
                                            extClasses='vkyc-step-option'
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );

            case ONBOARIDNG_STAGE_TYPE.FACE_MATCH:
                return (
                    <div className='vkyc-image-container'>
                        <AutoScaleImageViewer
                            url={passportFront}
                            baseContainerClass='vkyc-image-placeholder mr-30'
                            baseImageClass='vkyc-image'
                            landscapeContainerClass='vkyc-image-placeholder-horizontal'
                            landscapeImageClass='vkyc-image-horizontal'
                            handleReload={handleImageReload}
                        />
                        <div className='vkyc-image-placeholder'>
                            <ImageUtils
                                url={vkycData?.meetings?.[meetingId]?.stages?.[2]?.[CAMERA_ORIENTATION.front]?.imageUrl}
                                containerClass='vkyc-image'
                                handleImageReload={handleImageReload}
                            />
                        </div>
                        <div className='ml-30'>
                            <div className='mb-30'>
                                <div className='sop-step-des'>Match Score</div>
                                {
                                    !meetingLoadingState && (
                                        <div className='vkyc-match-score-container'>
                                            <div className={`vkyc-match-score-percentage
                                ${currentStage?.matchScore?.face_image_match_result?.match_score_percent >= 50
                                            ? 'vkyc-match-score-percentage--green' : 'vkyc-match-score-percentage--red'}
                                }`}
                                            >
                                                {matchScoreFormatting(currentStage?.matchScore?.face_image_match_result?.match_score_percent)}%
                                            </div>
                                            <div className='vkyc-match-score-progress'>
                                                <ProgressBar
                                                    number={currentStage?.matchScore?.face_image_match_result?.match_score_percent || 0}
                                                    progressBarStyleClassName={
                                                        currentStage?.matchScore?.face_image_match_result?.match_score_percent >= 50
                                                            ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='sop-step-des'>{currentStep?.face_match?.description}</div>
                            <div className='frWrapper'>
                                <Button
                                    primary
                                    v2
                                    label='Yes'
                                    onClick={() => {
                                        setAllAnsweredYes(true);
                                        handleFaceMatchSubmit(true);
                                    }}
                                    extClasses='vkyc-step-option'
                                />
                                <Button
                                    secondary
                                    v2
                                    label='Retake'
                                    onClick={() => {
                                        handleFaceMatchSubmit(false);
                                        setSubStageStep(subStageStep - 2);
                                    }}
                                    extClasses='vkyc-step-option'
                                />
                            </div>
                        </div>
                    </div>
                );

            case ONBOARIDNG_STAGE_TYPE.OCR_EXTRACTION: {
                const { err } = currentStage?.documentDetails || {};

                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.ocr_extraction?.instruction}</div>
                        <div className='vkyc-image-container'>
                            <AutoScaleImageViewer
                                url={passportFront}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                                handleReload={handleImageReload}
                            />
                            <div className=''>
                                {
                                    !meetingLoadingState && (
                                        <div className=''>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Name</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.passport_details?.name
                                                && nameFormatter(documentDetails?.document_details?.passport_details?.name)}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Date of birth</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.passport_details?.date_of_birth
                                                && dobFormatter(documentDetails?.document_details?.passport_details?.date_of_birth)}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Passport Number</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.passport_details?.passport_number}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Passport&apos;s Date of Expiry</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.passport_details?.date_of_expiry
                                                && dobFormatter(documentDetails?.document_details?.passport_details?.date_of_expiry)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='frWrapper'>
                                    <Button
                                        primary
                                        v2
                                        disabled={!!err}
                                        label='Continue'
                                        onClick={() => {
                                            dispatch(getMatchScore({
                                                livenessImageIdentifier: faceImage?.imageIdentifier,
                                                documentImageIdentifier: documentImageOne?.imageIdentifier,
                                                meetingId,
                                                documentDetails: documentDetails?.document_details,
                                                screenKey: [
                                                    'meetings',
                                                    meetingId,
                                                    'stages',
                                                    vkycData?.meetings?.[meetingId]?.stepNumber,
                                                    'matchScore',
                                                ],
                                            }));
                                            setSubStageStep(subStageStep + 1);
                                        }}
                                        extClasses='vkyc-step-option'
                                    />
                                    <Button
                                        primary
                                        v2
                                        label='Retake'
                                        onClick={() => {
                                            setSubStageStep(subStageStep - 1);
                                        }}
                                        extClasses='vkyc-step-option'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            case ONBOARIDNG_STAGE_TYPE.OCR_VALIDATION:
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.ocr_validation?.instruction}</div>
                        <div className=''>
                            <Table
                                rowDataVersion={2}
                                objKey='header_key'
                                labelData={currentStage?.matchScore?.ocr_details_match_results?.columnData}
                                contentData={currentStage?.matchScore?.ocr_details_match_results?.rowData}
                                // renderActionableColumn={renderActions}
                                prevToken={null}
                                nextToken={null}
                                paginatedClickHandler={() => {}}
                                extClasses={{
                                    container: 'p-20',
                                }}
                            />
                        </div>
                    </div>
                );

            default: return <div>Something went wrong</div>;
        }
    };

    return (
        <div>
            {getNextComponent()}
            <ErrorView
                errorObject={vkycData?.errorView}
                clickOptions={{
                    onPrimaryButtonClick: () => {
                        setSubStageStep(subStageStep - 1);
                        dispatch(setErrorViewAction({ data: null }));
                    },
                    onSecondaryButtonClick: () => {
                        dispatch(setErrorViewAction({ data: null }));
                    },
                }}
                isButtonV2
                extClasses={{
                    modal: {
                        button: 'vkyc-error-button',
                    },
                }}
                toastConfig={{ position: 'top-center' }}
                modalStyles={{
                    content: {
                        top: '40%',
                    },
                }}
            />
        </div>
    );
};

export default PassportCaptureStep;
