import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../components';

import VideoStreamLayout from '../VideoStreamLayout';
import { selectMeetingLoadingValue, selectMeetingId, selectHealthCheck } from '../selectors';
import { getOnboardingStages, setVKYCStep } from '../actions';

import CurrentStage from './CurrentStage';
import BottomOptions from './BottomOptions';

const CallScreen = (props) => {
    const dispatch = useDispatch();
    const { stopRecording } = props;
    const [nextStepEnable, setNextStepEnable] = useState(false);
    const meetingId = useSelector(selectMeetingId, shallowEqual);
    const meetingLoading = useSelector(selectMeetingLoadingValue(meetingId));
    const healthCheck = useSelector(selectHealthCheck, shallowEqual);

    useEffect(() => {
        if (healthCheck === 'PASSED') {
            dispatch(setVKYCStep({ step: 1 }));
            dispatch(getOnboardingStages({ meetingId, screenKey: ['meetings', meetingId] }));
        }
    }, [healthCheck]);

    return (
        <div className='vkyc-container'>
            <div className='vkyc-left-video-container'>

                <VideoStreamLayout />
            </div>
            <div className='vkyc-right-qna-container'>
                <div className='vkyc-qna-container'>
                    <CurrentStage stopRecording={stopRecording} setNextStepEnable={setNextStepEnable} />
                </div>
                <div className='vkyc-qna-line' />
                <BottomOptions nextStepEnable={nextStepEnable} setNextStepEnable={setNextStepEnable} stopRecording={stopRecording} />
            </div>
            <Loader visible={!!meetingLoading} loadingText={typeof meetingLoading === 'string' ? meetingLoading : ''} />
        </div>
    );
};

export default CallScreen;
