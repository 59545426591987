/**
 * @file Component to show error messages in toast or modal
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import InfoSVG from '../../assets/images/svgs/info.svg';

import { toastify } from '../../utils';

import Modal from '../Modal';
import Button from '../Button';

import { BUTTON_DISPLAY_THEME, ERROR_TYPES } from './constants';

import './styles.scss';

const ErrorView = (props) => {
    const {
        errorObject, clickOptions, isButtonV2, extClasses = {}, toastConfig, modalStyles,
    } = props;

    const toastCount = useRef(null);

    const { type, view_options: viewOptions } = errorObject || {};
    const { onPrimaryButtonClick, onSecondaryButtonClick, onTeritaryButtonClick } = clickOptions || {};
    const { toast: toastClasses, modal: modalClasses, inline: inlineClasses } = extClasses || {};

    const [modalVisible, setModalVisible] = useState(errorObject && errorObject[viewOptions]);

    useEffect(() => {
        if (errorObject && errorObject[viewOptions]) {
            setModalVisible(true);
            toastCount.current = 0;
        } else {
            setModalVisible(false);
            toastCount.current = null;
        }
    }, [errorObject, viewOptions]);

    switch (type) {
        case ERROR_TYPES.toast: {
            const { error_msg: msg } = errorObject[viewOptions] || {};
            if (toastCount.current === 0) {
                toastify(msg, 'error', 5000, toastConfig, toastClasses);
                toastCount.current += 1;
            }
            return null;
        }
        case ERROR_TYPES.modal: {
            const { error_msg: msg, actions } = errorObject[viewOptions] || {};
            const containerClasses = classNames('fccWrapper err-view-cr', modalClasses?.container);
            const buttonContainerClasses = classNames('frcWrapper err-view-button-cr', modalClasses?.buttonContainer);

            const getButtonClickFunction = (displayTheme) => {
                switch (displayTheme) {
                    case BUTTON_DISPLAY_THEME.DISPLAY_THEME_PRIMARY:
                        return onPrimaryButtonClick;
                    case BUTTON_DISPLAY_THEME.DISPLAY_THEME_SECONDARY:
                        return onSecondaryButtonClick;
                    case BUTTON_DISPLAY_THEME.DISPLAY_THEME_TERTIARY:
                        return onTeritaryButtonClick;
                    default:
                        return () => {};
                }
            };

            return (
                <Modal
                    visible={modalVisible}
                    modalStyles={modalStyles}
                >
                    <div className={containerClasses}>
                        <div className='err-view-icon'><img src={InfoSVG} alt='info' /></div>
                        <div>{msg}</div>
                        {
                            actions && Array.isArray(actions) && actions.length > 0 && (
                                <div className={buttonContainerClasses}>
                                    {
                                        actions.map((action) => {
                                            const { label, display_theme: displayTheme, is_disabled: isDisabled } = action;
                                            const key = `${label}-${Math.random().toString(36).substring(7)}`;
                                            const buttonType = BUTTON_DISPLAY_THEME[displayTheme];
                                            const onClick = getButtonClickFunction(buttonType);
                                            return (
                                                <Button
                                                    {...{ [buttonType]: true }}
                                                    key={key}
                                                    v2={isButtonV2}
                                                    label={label}
                                                    disabled={isDisabled}
                                                    extClasses={modalClasses?.button}
                                                    onClick={(e) => {
                                                        setModalVisible(false);
                                                        if (onClick && typeof onClick === 'function') onClick(e);
                                                    }}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                </Modal>
            );
        }
        case ERROR_TYPES.inline: {
            const { error_msg: msg, icon_url: iconUrl, should_show_retry_option: retryButtonVisible } = errorObject[viewOptions] || {};

            const containerClasses = classNames(inlineClasses?.container);
            const iconClasses = classNames(inlineClasses?.icon);
            const textClasses = classNames(inlineClasses?.text);
            const buttonClasses = classNames(inlineClasses?.button);

            return (
                <div className={containerClasses}>
                    {
                        iconUrl && <img src={iconUrl} className={iconClasses} alt='icon' />
                    }
                    <div className={textClasses}>{msg}</div>
                    {
                        retryButtonVisible && (
                            <Button
                                v2={isButtonV2}
                                primary
                                label='Retry'
                                extClasses={buttonClasses}
                                onClick={onPrimaryButtonClick}
                            />
                        )
                    }
                </div>
            );
        }
        default: {
            return null;
        }
    }
};

export default memo(ErrorView);
