/**
 * @file DocRedaction Selectors
 */

import { createSelector } from 'reselect';

import { DOC_REDACTION_KEY } from './constants';

export const makeSelectDocRedactionQueueCases = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'casesList']),
    (subState) => {
        const casesList = subState.toJS();
        const uiTableList = [];

        casesList
            .filter((item) => !!item.stock_guardian_ckyc_documents)
            .forEach((item, index) => {
                const { id, payload } = item || {};

                const curRowObj = {
                    id,
                    sr_no: index + 1,
                    actor_id: item[payload]?.application_id,
                    ogData: item,
                };

                uiTableList.push(curRowObj);
            });

        return uiTableList;
    },
);

export const selectDocRedactionCurCase = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'curCase']),
    (subState) => subState.toJS(),
);

export const selectDocRedactionCurView = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'curView']),
    (subState) => subState,
);

export const selectDocRedactionPayloadType = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'payloadType']),
    (subState) => subState.toJS(),
);

export const selectDocRedactionLoading = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'loading']),
    (subState) => subState,
);

export const selectDocRedactionErr = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'err']),
    (subState) => subState,
);

export const selectDocRedactionQueueFilters = () => createSelector(
    (state) => state.getIn([DOC_REDACTION_KEY, 'queueFilters']),
    (subState) => subState.toJS(),
);
