/**
 *
 * SOP - Main entry point component to handle all the routes related logic for SOP Section
 *
 */

import React, { memo, useContext } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';

import { injectReducer, injectSaga } from '../../utils';
import { MetaInfoContext } from '../../context';

import { SOP_PATH, VIEW_SOP_TREE_PATH, VIEW_SOP_USER_PATH } from '../AppV2/routes';

import ViewSOP from './ViewSOP';
import Customer from './Customer';
import Details from './Details';
import reducer from './reducer';
import saga from './saga';
import './style.scss';

const SOP = () => {
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            callHandlingV2,
        },
    } = metaInfo;
    return (
        <div className='sop'>
            <div className='sop-cr'>
                <Switch>
                    {
                        callHandlingV2 === 1 && <Route path={VIEW_SOP_USER_PATH} component={Customer} />
                    }
                    <Route path={VIEW_SOP_TREE_PATH} component={Details} />
                    <Route path={SOP_PATH} component={ViewSOP} />
                </Switch>
            </div>
        </div>
    );
};

const withReducer = injectReducer({ key: 'sop', reducer });
const withSaga = injectSaga({ key: 'sop', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(SOP));
