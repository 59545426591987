import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
    COMPLETE_SOP_FLOW_ENDPOINT, GET_PROFILE_ENDPOINT, GET_SOP_DETAILS_ENDPOINT, GET_SOP_TREE_ENDPOINT, TICKET_LIST_FOR_CALL_ENDPOINT,
    GET_RECENT_ACTIVITY_ENDPOINT, GET_RECENT_ACTIVITY_DETAILS_ENDPOINT, GET_ISSUE_CATEGORY_DETAILS_ENDPOINT,
    CUSTOMER_AUTH_ROOT, TICKETS_ROOT, CALL_INFO_ENDPOINT,
} from '../../api/routes';
import { getErrComponent } from '../../utils/errFormatter';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';

import { HOME_PATH } from '../AppV2/routes';

import {
    COMPLETE_SOP_FLOW, GET_PROFILE, GET_SOP_DETAILS, GET_SOP_NODE, STEP_TYPE, GET_USER_TICKETS,
    GET_RECENT_ACTIVITIES, GET_RECENT_ACTIVITY_DETAILS, GET_ISSUE_CATEGORY_DETAILS,
    MODERATELY_SENSITIVE_SCREENS, GET_AUTH_FACTOR, SENSITIVE_SCREENS, RESTRICTED_REVERSIBLE_SCREENS,
    ATTACH_TICKET_TO_CALL, GET_RECENT_QUERIES,
} from './constants';
import {
    completedSopFlowAction, errInCompletingSopFlowAction, errProfileAction, errSOPDetailsAction,
    errSOPNodeAction, setProfileAction, setSOPDetailsAction, setSOPNodeAction, setUserTicketsAction, errUserTicketsAction,
    setActivitiesAction, errActivitiesAction, setActivityDetailsAction, errActivityDetailsAction, setIssueCategoryDetailsAction,
    errIssueCategoryDetailsAction, errAuthFactor, setAuthFactor, setRecentQueriesAction, errRecentQueriesAction,
    errInAttachingTicketToCallAction, attachedTicketToCallAction,
} from './actions';

function* getProfileSaga(action) {
    const { ticketId, resolve, reject } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_PROFILE_ENDPOINT}?${queryString}`,
        );

        yield put(setProfileAction({ screenKey: ['userDetails'], value: { data: response } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        if (reject) yield call(reject, err);
        toastify(err, 'error');
        yield put(errProfileAction({ screenKey: ['userDetails'], err: getErrMsg(e) }));
    }
}

function* getActivitiesSaga(action) {
    const {
        ticketId, prevToken, resolve, reject,
    } = action.data;
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 365);
    const queryString = getQueryStringFromObject({
        ticketId,
        areas: [{ label: 'ALL', value: 'All' }],
        fromDate,
        toDate,
        prevToken,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_RECENT_ACTIVITY_ENDPOINT}?${queryString}`,
        );
        yield put(setActivitiesAction({ screenKey: ['userActivities'], value: { data: response } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        if (reject) yield call(reject, err);
        toastify(err, 'error');
        yield put(errActivitiesAction({ screenKey: ['userActivities'], err: getErrMsg(e) }));
    }
}

function* getActivityDetailsSaga(action) {
    const { ticketId, meta, resolve } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, meta,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_RECENT_ACTIVITY_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put(setActivityDetailsAction({ screenKey: ['userActivities', 'activityDetails'], value: { data: response?.sections } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errActivityDetailsAction({ screenKey: ['userActivities', 'activityDetails'], err: getErrMsg(e) }));
    }
}

function* getIssueCategoryDetailsSaga(action) {
    const { meta, resolve } = action.data;
    const queryString = getQueryStringFromObject({
        meta,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_ISSUE_CATEGORY_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put(setIssueCategoryDetailsAction({ screenKey: ['userActivities', 'issueCategoryDetails'], value: { data: response } }));
        if (resolve) yield call(resolve, response);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errIssueCategoryDetailsAction({ screenKey: ['userActivities', 'issueCategoryDetails'], err: getErrMsg(e) }));
    }
}

function* getUserTicketsSaga(action) {
    const {
        callerUserId, fetchFromSource, resolve, statusList,
    } = action.data;
    const queryString = getQueryStringFromObject({ callerUserId, fetchFromSource, statusList });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKET_LIST_FOR_CALL_ENDPOINT}?${queryString}`,
        );
        yield put(setUserTicketsAction({ screenKey: ['userTickets'], value: { data: response } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errUserTicketsAction({ screenKey: ['userTickets'], err: getErrMsg(e) }));
    }
}

function* getSOPDetailsSaga(action) {
    const { sopId, resolve } = action.data;
    const queryString = getQueryStringFromObject({ sopId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SOP_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put(setSOPDetailsAction({ screenKey: 'tree', value: response }));
        const { data } = response;
        const { sourceNode, title } = data || {};
        if (resolve) yield call(resolve, { sourceNode, title });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSOPDetailsAction({ screenKey: 'tree', err: getErrMsg(e) }));
    }
}

function* getSOPNodeSaga(action) {
    const {
        sopId, nodeId, resolve, ticketId, isSecondRpcCall, rpcsToBeCalled,
    } = action.data;
    const queryString = getQueryStringFromObject({
        sopId, nodeId, ticketId, isSecondRpcCall, rpcsToBeCalled,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SOP_TREE_ENDPOINT}?${queryString}`,
        );
        yield put(setSOPNodeAction({ value: response }));
        if (resolve) yield call(resolve);
        if (response?.stepType === STEP_TYPE.infoDisplay) {
            const { errorRpcs } = response?.step || {};
            if (errorRpcs && Array.isArray(errorRpcs) && errorRpcs.length > 0) {
                const error = new Error();
                error.errorRpcs = errorRpcs;
                throw error;
            }
        }
    } catch (e) {
        const { errorRpcs } = e || {};
        if (errorRpcs && Array.isArray(errorRpcs)) {
            errorRpcs.forEach((rpcErr) => {
                const { error } = rpcErr;
                const err = getErrLabel(error);
                toastify(err, 'error');
            });
            return;
        }
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSOPNodeAction({ screenKey: ['tree', 'nodes'], err: getErrMsg(e) }));
    }
}

function* completeSopFlowSaga(action) {
    const {
        sopId, userResponses, resolve, ticketId,
    } = action.data;
    const queryString = getQueryStringFromObject({ sopId, ticketId });
    const payload = {
        userResponses,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${COMPLETE_SOP_FLOW_ENDPOINT}?${queryString}`,
            payload,
        );

        if (response.status) {
            if (response.status.code === 0) {
                yield put(completedSopFlowAction({ screenKey: ['tree'] }));
                toastify(response.status?.short_message || 'Successfully Uploaded', 'success');
                if (resolve) yield call(resolve);
            } else {
                throw new Error(response.status?.short_message);
            }
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errInCompletingSopFlowAction({ screenKey: ['tree'], err: getErrMsg(e) }));
    }
}

function* checkForAuthScreens(action) {
    const {
        screen, path, state,
    } = action.data;

    // Check if the screen is in any of the sensitive screen lists
    if (!(
        MODERATELY_SENSITIVE_SCREENS.includes(screen)
        || SENSITIVE_SCREENS.includes(screen)
        || RESTRICTED_REVERSIBLE_SCREENS.includes(screen)
    )) {
        // If the screen is not sensitive, redirect to the specified path or home
        if (!path) {
            yield put(push(HOME_PATH, state));
        } else {
            yield put(push(path, state));
        }
        return false; // Indicate that the screen is not sensitive
    }
    return true; // Indicate that the screen is sensitive
}

function* getAuthFactorSaga(action) {
    const {
        ticketId, ucid, isInsensitiveAuth, fallbackScreenData, resolve, reject,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, ucid, is_insensitive_auth: isInsensitiveAuth });

    try {
        // Make an API call to get the authentication factor
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CUSTOMER_AUTH_ROOT}?${queryString}`,
        );

        // Check if the screen requires authentication
        const checkPassed = yield call(
            checkForAuthScreens,
            {
                data: {
                    screen: response.screen, ticketId, ...fallbackScreenData,
                },
            },
        );

        if (!checkPassed) {
            // If authentication is insufficient, handle rejection
            if (reject) yield call(reject);
            yield put(errAuthFactor({ err: 'Current Auth is insufficient' }));
            toastify('Current Auth is insufficient', 'error');
        } else {
            // If authentication is sufficient, resolve and set the auth factor
            if (resolve) yield call(resolve);
            yield put(setAuthFactor({ value: response }));
        }
    } catch (e) {
        // Handle errors during the API call
        const err = getErrLabel(e);
        if (reject) yield call(reject, err);
        toastify(err, 'error');
        yield put(errAuthFactor({ err: getErrMsg(e) }));
    }
}

function* getRecentQueriesSaga(action) {
    const { ticketId, resolve, reject } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/user-queries`,
        );

        if (resolve) yield call(resolve, response);
        yield put(setRecentQueriesAction({ value: response }));
    } catch (e) {
        const { errorView, errorLabel } = getErrComponent(e, false);
        if (reject) yield call(reject, errorLabel);
        yield put(errRecentQueriesAction({ err: errorView || errorLabel }));
    }
}

function* attachTicketToCallSaga(action) {
    const {
        ucid, ticketId, resolve, reject,
    } = action.data;
    const payload = {
        ucid, ticketId,
    };

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.put],
            CALL_INFO_ENDPOINT,
            payload,
        );
        if (resolve) yield call(resolve);
        yield put(attachedTicketToCallAction({}));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        if (reject) yield call(reject);
        yield put(errInAttachingTicketToCallAction({ err: getErrMsg(e) }));
    }
}

export default function* SopSaga() {
    yield all(
        [
            yield takeLatest(GET_PROFILE, getProfileSaga),
            yield takeLatest(GET_RECENT_ACTIVITIES, getActivitiesSaga),
            yield takeLatest(GET_RECENT_ACTIVITY_DETAILS, getActivityDetailsSaga),
            yield takeLatest(GET_ISSUE_CATEGORY_DETAILS, getIssueCategoryDetailsSaga),
            yield takeLatest(GET_USER_TICKETS, getUserTicketsSaga),
            yield takeLatest(GET_SOP_DETAILS, getSOPDetailsSaga),
            yield takeLatest(GET_SOP_NODE, getSOPNodeSaga),
            yield takeLatest(COMPLETE_SOP_FLOW, completeSopFlowSaga),
            yield takeLatest(GET_AUTH_FACTOR, getAuthFactorSaga),
            yield takeLatest(ATTACH_TICKET_TO_CALL, attachTicketToCallSaga),
            yield takeLatest(GET_RECENT_QUERIES, getRecentQueriesSaga),
        ],
    );
}
