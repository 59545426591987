/**
 * @file Utilities for Transaction view V2
 */

import * as d3 from 'd3';

import { getD3Time } from '../../components/d3/utils';
import { convertMoneytoAmount } from '../../utils/currencyHelper';

// eslint-disable-next-line import/no-cycle
import {
    AMOUNT, TRANSACTIONCOUNT, periodMap, TRANSACTION_VIEW_EVENT_THRESHOLD,
} from './constants';

/**
 * Convert Date in milliseconds into timestamp
 * @param {number} milliseconds - Date in milliseconds
 * @returns {{seconds: number, nanos: number}} timestamp object of seconds and nanos
 */
export function convertMillisecondsToTimestamp(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const nanos = (milliseconds % 1000) * 1e6; // Convert milliseconds to nanoseconds

    return {
        seconds,
        nanos,
    };
}

/**
 * Convert Timestamp into Date in milliseconds
 * @param {object} timestamp - timestamp object of seconds and nanos
 * @returns {number | null} Date in millseconds or null
 */
export function convertTimestampToMilliseconds(timestamp) {
    // check for valid timestamp object
    if (timestamp != null && typeof timestamp === 'object' && 'seconds' in timestamp) {
        // convert and add seconds in millisconds and nanos in milliseconds
        return Number(timestamp?.seconds || 0) * 1000 + Math.floor(Number(timestamp?.nanos || 0) / 1e6);
    }
    return null;
}

/**
 * Convert raw Transaction view data into table readable data
 * @param {Record<string, any>[]} data -  Raw Transaction view Data
 * @returns {Record<string, any>[]}  Modified Transction View Data
 */
export const dataForTableConverter = (data) => data.map((item) => ({
    ...item,
    order_created_at: convertTimestampToMilliseconds(item.order_created_at_ist),
    credited_at: convertTimestampToMilliseconds(item.credited_at_ist),
    debited_at: convertTimestampToMilliseconds(item.debited_at_ist),
    credited_debited_at: convertTimestampToMilliseconds(item.credited_at_ist || item.debited_at_ist),
    executed_at: convertTimestampToMilliseconds(item?.executed_at_ist),
    amount: convertMoneytoAmount(item.amount),
    transaction_status: (item.transaction_status || ''),
    money_flow: item.money_flow?.replace('MONEY_FLOW_', ''), // replacing MONEY_FLOW with empty string
    location: item.location ? `${item.location.city} ${item.location.state}` : '', // combine city and state
    tripped_rules: (item?.tripped_rules || []).join(', '), // convert array into comma separated string
}));

/**
 * Modify the time in the Date obejct to start or end hour of the date
 * @param {Date} date - Date Object
 * @param {'start'|'end'} timeOfDay - Either start time or end time
 * @returns {number | null} Modified start or end date in millseconds or null
 */
export const getStartOrEndTime = (date, timeOfDay = 'start') => {
    if (!(date instanceof Date)) {
        return null;
    }

    let values = [0, 0, 0, 0]; // set start time as default [hour,min,second,millseconds]

    if (timeOfDay === 'end') {
        values = [23, 59, 59, 999]; // set end time [hour,min,second,millseconds]
    }

    const modifiedDateInMillseconds = date.setHours(...values);

    return modifiedDateInMillseconds;
};

// callback to get amount sum and transaction count obejct
export const amountAndTransactionCount = (group) => ({
    [AMOUNT]: d3.sum(group, (d) => d.amount), // Calculate the sum of amounts
    [TRANSACTIONCOUNT]: d3.count(group, (d) => d.amount), // Calculate the no.of transactions
});

export const aggregatedValue = (group) => ({
    value: d3.sum(group, (d) => d.value),
});

/**
 *  Transction data grouper function of graph
 * @param {object} data transaction data
 * @param {string} aggregation (month,week,day,ect..)
 * @param {string} barKey key to group data
 * @param {function} groupCallback callback to aggregate grouped data like sum,count,ect..
 * @returns {object} aggregated sorted data
 */
export const dataGrouper = (data, aggregation, barKey, groupCallback = amountAndTransactionCount) => {
    // grouping based on date and barKey
    const groupedData = d3.group(data, (d) => {
        const key = getD3Time(aggregation)(d.date);
        return `${key}/${d[barKey]}`;
    });

    // Convert the grouped data into an array of objects
    const result = Array.from(groupedData, ([key, group]) => {
        const [date, type] = key.split('/');
        return {
            date: new Date(date).getTime(),
            [barKey]: type,
            ...groupCallback(group),
        };
    });

    return d3.sort(result, (a, b) => d3.ascending(a.date, b.date));
};

/**
 *  Transction data grouper function of graph
 * @param {object} data transaction data
 * @param {string} aggregation (month,week,day,ect..)
 * @param {string} barKey key to group data
 * @returns {object} aggregated sorted data
 */
export const aggregatedDataGrouper = (data, aggregation, barKey) => {
    if (!data) return null;

    // grouping based on date and barKey
    const groupedData = d3.group(data, (d) => {
        const key = getD3Time(aggregation)(d.date);
        return `${key}/${d[barKey]}`;
    });

    // Convert the grouped data into an array of objects
    const result = Array.from(groupedData, ([key, group]) => {
        const [date, type] = key.split('/');
        if (Array.isArray(group) && group.length > 0) {
            return {
                date: new Date(date).getTime(),
                [barKey]: type,
                annotations: group[0].annotations,
                ...aggregatedValue(group),
            };
        }
        return {
            date: new Date(date).getTime(),
            [barKey]: type,
            ...aggregatedValue(group),
        };
    });

    return d3.sort(result, (a, b) => d3.ascending(a.date, b.date));
};

/**
 * Get From Date based on period
 * @param {*} period
 * @returns {Date}
 */
export const getFromDateBasedOnPeriod = (period) => {
    const defaultFromDate = new Date();
    const periodData = periodMap[period];

    if (periodData) {
        defaultFromDate.setDate(defaultFromDate.getDate() - periodData.days);
        // enters when vaild startDate is found.
        if (periodData.startDate) {
            // startDate is to set date of the defaultFromDate to a certain date.
            defaultFromDate.setDate(periodData.startDate);
        }
    }

    return defaultFromDate;
};

/**
 * timestamp value
 * @param {*} customDate : { day, month, year , time }
 * @returns {timestamp}
 */
export const toTimestampFormat = (customDate) => {
    const {
        day, month, year, time = 0,
    } = customDate;
    return new Date(year, month - 1, day, time).getTime();
};

/**
 * format aggregated data for bar-chart
 * @param samplePoints: { Charts: { title, chart_type, chart_data: { bar_chart : { data_points } }  }  }
 * @returns { title, chartType, dataPoints, bars, xReferenceLines, xRefs, barCount }
 */

export const formattedAggregatedData = (samplePoints) => {
    try {
        if (samplePoints && Array.isArray(samplePoints.Charts) && samplePoints.Charts.length > 0) {
            const aggregatedCharts = samplePoints.Charts.map((chart) => {
                const dataPoints = [];
                const barSet = new Set();
                const xReferenceLines = [];
                let graphTitle = '';
                let graphChartType = '';
                const refSet = new Set();
                const {
                    title: {
                        text,
                    },
                    chart_type: chartType,
                    chart_data: {
                        bar_chart: {
                            reference_lines: xRefLines,
                            data_points: dataPointsList,
                        },
                    },
                    x_axis_default_zoom_level: xZoomLevel,
                } = chart || {
                    title: {
                        text: '',
                    },
                    chart_type: '',
                    chart_data: {
                        bar_chart: {
                            data_points: [],
                        },
                    },
                };

                graphTitle = text;
                graphChartType = chartType;

                let xIndex = 0;
                if (Array.isArray(xRefLines)) {
                    xRefLines.forEach((item) => {
                        xReferenceLines.push(item);
                        refSet.add(item.label);
                    });
                }

                dataPointsList.forEach((item) => {
                    xIndex = toTimestampFormat(item.date) + 1;
                    let innerIndex = xIndex;

                    const annotations = item?.annotations;

                    if (Array.isArray(annotations)) {
                        annotations.forEach((annotation) => {
                            refSet.add(annotation.label);
                        });
                    }

                    item.values.forEach(((sample) => {
                        barSet.add(sample.series_label);
                        dataPoints.push({
                            type: sample.series_label,
                            value: sample.value,
                            date: innerIndex,
                            label: toTimestampFormat(item.date),
                            annotations: item.annotations,
                        });
                        innerIndex += 1;
                    }));
                });

                return {
                    title: graphTitle,
                    chartType: graphChartType,
                    dataPoints,
                    bars: Array.from(barSet),
                    xReferenceLines,
                    xRefs: Array.from(refSet),
                    barCount: barSet.size - refSet.size,
                    xZoomLevel,
                };
            });
            return aggregatedCharts;
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

/**
 * format allowed annotations fields
 * @param {*} uiElementAllowedAnnotations: { annotation_types_with_values }
 * @returns { annotationTypeList, annotationOptionMapping }
 */

export const formatAllowedAnnotationsData = (uiElementAllowedAnnotations = {}) => {
    const annotationOptionMapping = {};

    const { annotation_types_details: annotationTypeDetails } = uiElementAllowedAnnotations;

    const annotationTypeList = annotationTypeDetails?.map((item) => {
        const { mapping, type_with_values: typeWithValue } = item;

        annotationOptionMapping[typeWithValue.type.id] = {
            isMultiSelect: mapping?.is_multi_select,
            isMandatory: mapping?.is_mandatory,
            entityType: typeWithValue?.type.entity_type,
            options: typeWithValue?.allowed_annotations?.map((annotation) => ({
                label: annotation?.value,
                value: annotation?.id,
            })),
        };

        return {
            label: typeWithValue?.type?.display_text,
            value: typeWithValue?.type?.id,
        };
    });

    return { annotationTypeList, annotationOptionMapping };
};

export const setViewTimeEvent = (eventName, topPosition, metricsEvent, fireEvent) => {
    // setting start point for observation

    if (typeof fireEvent !== 'function') return;

    const currentEvent = metricsEvent.events[eventName];

    if (currentEvent.start === TRANSACTION_VIEW_EVENT_THRESHOLD.START_INIT && topPosition !== TRANSACTION_VIEW_EVENT_THRESHOLD.TOP_POSITION
         && topPosition !== 0
          && window.innerHeight / topPosition > TRANSACTION_VIEW_EVENT_THRESHOLD.START) {
        currentEvent.start = Date.now();
        metricsEvent.setEvent(eventName, currentEvent);
    }

    if (currentEvent.start !== TRANSACTION_VIEW_EVENT_THRESHOLD.START_INIT) {
        currentEvent.end = Date.now();
        metricsEvent.setEvent(eventName, currentEvent);

        const viewTime = currentEvent.end - currentEvent.start;
        if (Math.abs(window.innerHeight / topPosition) > TRANSACTION_VIEW_EVENT_THRESHOLD.END
         && viewTime > TRANSACTION_VIEW_EVENT_THRESHOLD.VIEW_TIME) {
            // fire rudder event event;

            fireEvent(
                currentEvent.name,
                {
                    type: currentEvent.name,
                    start: currentEvent.start,
                    end: currentEvent.end,
                    viewTime,
                },
            );

            metricsEvent.resetEvent(eventName);
        }
    }
};
