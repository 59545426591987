/**
*
* Loader
*
*/

import React, { memo } from 'react';

import Modal from '../Modal';

import './style.scss';

const customStylesForModal = (isLoadingText) => ({
    overlay: {
        backgroundColor: isLoadingText ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        inset: isLoadingText ? '50% auto auto 50%' : '50% 0 0 50%',
        transform: isLoadingText ? 'translateX(-50%) translateX(-20px)' : 'translate(-50%, -50%)',
        width: isLoadingText ? '300px' : '60px',
        height: !isLoadingText && 60,
        borderRadius: isLoadingText ? 12 : 30,
        padding: isLoadingText ? '32px 20px' : 10,
        alignItems: isLoadingText && 'center',
    },
});

export const ComponentLoader = (props) => {
    const { visible, text } = props;
    if (visible) {
        return (
            <div className='custom-loader'>
                <span className='custom-loader-label'><div className='loader' />{text && <span className='custom-loader-text'>{text}</span>}</span>
            </div>
        );
    }
    return null;
};

const Loader = (props) => {
    const { visible, parentNode, loadingText } = props;

    return (
        <Modal
            visible={visible}
            modalStyles={customStylesForModal(!!loadingText)}
            parentSelector={() => document.querySelector(parentNode || '#root')}
            contentLabel='Loader Modal'
        >
            <div className='loader' />
            <div className='loader-text'>{loadingText}</div>
        </Modal>
    );
};

export default memo(Loader);
