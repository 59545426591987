/**
 * @file WealthOps Reducer
 */

import { fromJS } from 'immutable';

import {
    GET_DOC_REDACTION_QUEUE, SET_DOC_REDACTION_QUEUE, ERR_DOC_REDACTION, VIEW_ALL_DOC_REDACTION_CASES, SET_DOC_REDACTION_PAYLOAD_TYPE,
    PAYLOAD_DD_OG_STATE, SET_DOC_REDACTION_CUR_VIEW, SET_DOC_REDACTION_CUR_CASE, SET_DOC_REDACTION_QUEUE_FILTERS,
    LIMIT_DD_OG_STATE, EXECUTE_DOC_REDACTION, EXECUTED_DOC_REDACTION, GET_PRESIGNED_DOCUMENT_URLS, SET_PRESIGNED_DOCUMENT_URLS,
} from './constants';

const initialState = fromJS({
    casesList: [],
    curCase: {},
    curView: VIEW_ALL_DOC_REDACTION_CASES,
    payloadType: PAYLOAD_DD_OG_STATE,
    loading: false,
    err: '',
    queueFilters: {
        limit: LIMIT_DD_OG_STATE,
    },
});

function documentReviewReducer(state = initialState, action = {}) {
    switch (action.type) {
        case EXECUTE_DOC_REDACTION:
        case GET_DOC_REDACTION_QUEUE: {
            const { casesList } = action.data;

            let modifiedState = state
                .set('loading', true)
                .set('err', '');

            if (casesList) modifiedState = modifiedState.set('casesList', fromJS(casesList));

            return modifiedState;
        }

        case SET_DOC_REDACTION_QUEUE: {
            const casesList = action.data;

            return state
                .set('loading', false)
                .set('casesList', fromJS(casesList));
        }

        case GET_PRESIGNED_DOCUMENT_URLS: {
            return state
                .set('loading', true);
        }

        case ERR_DOC_REDACTION: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_DOC_REDACTION_CUR_VIEW: {
            return state.set('curView', action.data);
        }

        case SET_DOC_REDACTION_CUR_CASE: {
            return state.set('curCase', fromJS(action.data));
        }

        case SET_DOC_REDACTION_PAYLOAD_TYPE: {
            return state
                .set('payloadType', fromJS(action.data));
        }

        case SET_DOC_REDACTION_QUEUE_FILTERS: {
            const { key, value } = action.data;

            return state
                .setIn(['queueFilters', key], fromJS(value));
        }

        case SET_PRESIGNED_DOCUMENT_URLS: {
            return state
                .set('loading', false);
        }

        case EXECUTED_DOC_REDACTION: {
            return state.setIn(['loading'], false);
        }

        default:
            return state;
    }
}

export default documentReviewReducer;
